import { getCAConfig } from '../utils/ca-env';
import { isEnabledForMobile } from '../utils/mobile-utils';
/* eslint-disable @typescript-eslint/no-explicit-any */
type NRAttributes = {
  logLevel: 'error' | 'info' | 'warn';
  message: string;
  logCode: ILogCode;
  logName: ILogName;
  locale: string;
  deviceType: string;
  env: string;
  deploymentName: string;
  [x: string]: any;
};

type ILogName =
  | 'CA_ERROR' // CA generic error
  | 'CA_FIRE_IMPRESSION_ERROR' // Optimizely Error
  | 'CA_PAGE_PROPERTIES_ERROR' // Error Occured in computing page properties
  | 'CA_EVENTS_ERROR' // Error in SF Events
  | 'CA_OUTSIDE_BUSINESS_HR' // Business Hr Check
  | 'CA_AGENT_UNAVAILABLE' // Agent Unavailablity
  | 'CA_CHAT_ESTABLISHED' // Chat Established;
  | 'CA_CHAT_REQUEST_SUCCESS'
  | 'CA_AGENT_CONNECTION_LOST'
  | 'CA_HELP_BUTTON_CLICKED'
  | 'CA_CHAT_ENDED_BY_USER'
  | 'CA_CHAT_ENDED_BY_AGENT'
  | 'CA_DISABLED_CLICK'
  | 'CA_CHANNEL_PROVIDER_ENTRY_NOT_FOUND'
  | 'CA_BUSINESS_HR_END'
  | 'CA_HIDE_ON_BUSINESS_HR_END'
  | 'CA_AGENT_UNAVAILABILITY'
  | 'CA_RETRY_BUTTON_HOOP'
  | 'CA_DESIGN_LINK';

type ILogCode =
  // For Errors/warn  use logCode CA0XX
  | 'CA001'
  | 'CA002'
  | 'CA003'
  | 'CA004'
  // For Info use logCode CA9XX
  | 'CA999'
  | 'CA998'
  | 'CA997'
  | 'CA996'
  | 'CA995'
  | 'CA994'
  | 'CA993'
  | 'CA992'
  | 'CA991'
  | 'CA990'
  | 'CA989'
  | 'CA988'
  | 'CA987'
  | 'CA986'
  | 'CA985'
  | 'CA984'
  | 'CA983'
  | 'CA982'
  | 'CA981'
  | 'CA980'
  | 'CA979';

const logCodeMapping: { [x in ILogName]: ILogCode } = {
  CA_ERROR: 'CA001',
  CA_FIRE_IMPRESSION_ERROR: 'CA002',
  CA_PAGE_PROPERTIES_ERROR: 'CA003',
  CA_EVENTS_ERROR: 'CA004',
  CA_OUTSIDE_BUSINESS_HR: 'CA999',
  CA_AGENT_UNAVAILABLE: 'CA998',
  CA_CHAT_ESTABLISHED: 'CA997',
  CA_CHAT_REQUEST_SUCCESS: 'CA996',
  CA_AGENT_CONNECTION_LOST: 'CA995',
  CA_HELP_BUTTON_CLICKED: 'CA994',
  CA_CHAT_ENDED_BY_USER: 'CA993',
  CA_CHAT_ENDED_BY_AGENT: 'CA992',
  CA_DISABLED_CLICK: 'CA986',
  CA_CHANNEL_PROVIDER_ENTRY_NOT_FOUND: 'CA984',
  CA_BUSINESS_HR_END: 'CA983',
  CA_HIDE_ON_BUSINESS_HR_END: 'CA982',
  CA_AGENT_UNAVAILABILITY: 'CA981',
  CA_RETRY_BUTTON_HOOP: 'CA980',
  CA_DESIGN_LINK: 'CA979',
};

const logMapping = (logName: ILogName): NRAttributes => {
  const caChatConfig = getCAConfig();
  const { locale, env } = caChatConfig;

  const commonAttributes = {
    deploymentName: `chat-anywhere-${locale}-chat`,
    locale,
    deviceType: isEnabledForMobile() ? 'mobile' : 'desktop',
    env: env?.includes('production') ? 'production' : 'dev',
    logCode: logCodeMapping[logName],
    logName,
  };
  switch (logName) {
    case 'CA_FIRE_IMPRESSION_ERROR':
      return { ...commonAttributes, logLevel: 'error', message: 'CA: Error occured in Fire Impression' };
    case 'CA_PAGE_PROPERTIES_ERROR':
      return { ...commonAttributes, logLevel: 'error', message: 'CA: Error occured in computing page properties' };
    case 'CA_EVENTS_ERROR':
      return { ...commonAttributes, logLevel: 'error', message: 'CA: Error in saleforce Events' };
    case 'CA_OUTSIDE_BUSINESS_HR':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Outside Business Hour' };
    case 'CA_AGENT_UNAVAILABLE':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Agent Unavailable' };
    case 'CA_CHAT_ESTABLISHED':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Chat Established' };
    case 'CA_CHAT_REQUEST_SUCCESS':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Chat Request Success' };
    case 'CA_AGENT_CONNECTION_LOST':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Chat Agent Connection lost' };
    case 'CA_HELP_BUTTON_CLICKED':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Help Button Clicked' };
    case 'CA_CHAT_ENDED_BY_USER':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Chat ended by user' };
    case 'CA_CHAT_ENDED_BY_AGENT':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Chat ended by agent' };
    case 'CA_DISABLED_CLICK':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Disabled CTA clicked' };
    case 'CA_CHANNEL_PROVIDER_ENTRY_NOT_FOUND':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Channer provider Entry not found' };
    case 'CA_BUSINESS_HR_END':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Business Hour ending' };
    case 'CA_HIDE_ON_BUSINESS_HR_END':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Chat button hidden on business hr end' };
    case 'CA_AGENT_UNAVAILABILITY':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Agent Unavailable during BH' };
    case 'CA_RETRY_BUTTON_HOOP':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Retry Chat Button Clicked' };
    case 'CA_DESIGN_LINK':
      return { ...commonAttributes, logLevel: 'info', message: 'CA: Design Link generate event' };
    case 'CA_ERROR':
    default:
      return { ...commonAttributes, logLevel: 'error', message: 'CA: Error occured ' };
  }
};
export { ILogName, ILogCode, NRAttributes, logCodeMapping, logMapping };
