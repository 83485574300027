import { getCTAForPageUrl, getButtonHTML } from '../HTML/CAButton';
import { eventBus } from '../../../utils/eventBus';
import { WindowType } from '../../../types/windowType';
import { conflictingPageMapping, conflictingMobilePageMapping, helpPanelAnywherePages } from '../pageMapping';
import { getTrans } from './translations';
import { logNR } from '../../../utils/newRelic';
import { isChatEnabled } from '../../../Services/channelProviderApi';
import { showChatChipOnInit } from '../HTML/CAButton';
import { getStatsigVariation } from '../../../utils/experiment-utils';
import { isStudioPage, addConflictingPageCSSClass } from '../../../CACTA/JSComponents/JS/eventManagement';
import { _initChatByEvent, _cutomButtonClicked } from '../../../CAEmbeddedChat/embeddedChatMain';

import {
  hideSearchOrDesignHelpButton,
  isPPAGLearnPage,
  isPPAGFreeSamples,
  isSearchOrDesignPage,
  updatePPAGInlineButton,
} from '../../../Services/legacyChatReplacementService';
import { isMobileOrTablet } from '../../../utils/mobile-utils';
import AnalyticsTracking from '../../../CAEmbeddedChat/eventTracking';
import { getVariation } from '@vp/ab-reader';
import { getCAConfig } from '../../../utils/ca-env';
import { isHelpCenterHomepage } from '../../../Services/helpCenterService';

const isSafari = () => {
  var userAgent = navigator.userAgent;
  return userAgent.includes('Safari') && !userAgent.includes('Chrome');
};

// /** API Call to channel provider to get chat status */
// let isChatEnabledFromChannelProvider = true;

function playNotification() {
  try {
    if (!isSafari()) {
      const audio = new Audio('https://dqq742lm0w7m1.cloudfront.net/JS-build/incoming.mp3');
      audio.play();
    } else {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const audioSource = audioContext.createBufferSource();

      fetch('https://dqq742lm0w7m1.cloudfront.net/JS-build/incoming.mp3')
        .then((response) => response.arrayBuffer())
        .then((data) => audioContext.decodeAudioData(data))
        .then((buffer) => {
          audioSource.buffer = buffer;
          audioSource.connect(audioContext.destination);
          audioSource.start();
        })
        .catch((error) => console.error(error));
    }
  } catch (e) {
    console.log(e);
  }
}

const hideCAButton = () => {
  console.log('hideCAButton');
  const customButton = document.getElementById('caHelpButton');
  if (customButton && !isPPAGFreeSamples()) {
    // (customButton as HTMLButtonElement).disabled = true;
    (customButton as HTMLButtonElement).style.display = 'none';
  }
};
const showCAButton = (setDisabled?: boolean) => {
  console.log('showCAButton', { setDisabled });
  const customButton = document.getElementById('caHelpButton');
  if (customButton) {
    if ((customButton as HTMLButtonElement).classList.contains('hideCTAbutton')) {
      (customButton as HTMLButtonElement).classList.remove('hideCTAbutton');
    }
    // (customButton as HTMLButtonElement).disabled = setDisabled ? setDisabled : false;
    // if (isEnabledForMobile()) {
    //   (customButton as HTMLButtonElement).disabled = true;
    // } else {
    //   (customButton as HTMLButtonElement).disabled = false;
    // }
    (customButton as HTMLButtonElement).style.display = 'flex';
  }
};

/** API Call to channel provider to get chat status */
let isChatEnabledFromChannelProvider = true;

/** Fn to dispatch init chat event , after CA button is visible */
const initChat = (data) => {
  eventBus.dispatch('initChat', data);
};
declare const window: WindowType;
/** Fn to handle CTA Click, when the custon button is clicked,
 *  this will click the Saleforce chat button to open chat */

const onCAButtonClicked = (data = {}) => {
  //Play silent sound first time as safari doesn't allow sound to play without DOM interaction according to its autoplay audio policy
  const audio = new Audio('https://dqq742lm0w7m1.cloudfront.net/JS-build/incoming.mp3');
  audio.muted = true;
  audio.play();

  eventBus.dispatch('onCAButtonClicked', data);
  const customButton = document.getElementById('caButtonText');
  (customButton as HTMLElement).innerText = getTrans('ca-loading');
};

// Check for firefox browser
const isFirefox = () => {
  return navigator.userAgent.indexOf('Firefox') != -1;
};

const addRootDivListeners = () => {
  const rootDiv = document.getElementById('caRootDiv');
  const rootDivClickhandler = () => {
    // Tracking CA button click on disable state VIR-1762
    const customButton = document.getElementById('caHelpButton');
    const isCAButtonDisabled = (customButton as HTMLButtonElement)?.disabled;
    if (isCAButtonDisabled) {
      console.log('clicked on disable button');
      logNR('CA_DISABLED_CLICK');
    } else {
      (rootDiv as HTMLElement)?.removeEventListener('click', rootDivClickhandler, false);
    }
  };
  (rootDiv as HTMLElement)?.addEventListener('click', rootDivClickhandler);

  document.addEventListener('click', function (event) {
    const target = event.target as HTMLElement;
    if (target?.innerText?.includes(getTrans('retry-chat'))) {
      console.log('CA_RETRY_BUTTON_HOOP');
      logNR('CA_RETRY_BUTTON_HOOP');
    }
  });
};

/** chatLoadStartTime timestamp as soon as the script is injected , will be used to find total load time  */
let chatLoadStartTime = new Date();
export const getStartTime = () => chatLoadStartTime;

/** Utility fn to convert to 2 dec places and return as number */
export const tofixedDecimal = (num: number) => Number(num.toFixed(2));

const isHelpPanelAnywherePage = () => {
  const caChatConfig = getCAConfig();
  const pageUrl = window.location.href;
  const currentLocale = caChatConfig.locale;
  const allowedLocales = [
    'en-us',
    'en-gb',
    'en-ie',
    'en-ca',
    'en-au',
    'en-nz',
    'en-sg',
    'fr-fr',
    'fr-ca',
    'fr-ch',
    'fr-be',
    'de-de',
    'de-at',
    'de-ch',
    'it-ch',
    'it-it',
    'nl-nl',
    'nl-be',
    'es-es',
    'es-us',
    'da-dk',
    'fi-fi',
    'nb-no',
    'sv-se',
  ];
  return helpPanelAnywherePages.find(
    (ele) => pageUrl.includes(getTrans(ele)) && allowedLocales.includes(currentLocale.toLocaleLowerCase()),
  ) && isStudioPage()
    ? true
    : false;
};

const isCASession = () => {
  const caSessionData = sessionStorage.getItem('ca-user');
  if (caSessionData && caSessionData == 'non-proadv') {
    return true;
  }
  return false;
};

const sendEventToHelpAnywherePanel = (type, additionalInfo = {}) => {
  eventBus.dispatch('eventToHelpAnywherePanel', {
    origin: 'CA',
    type: type,
    additionalInfo: additionalInfo,
  });
};

const sendEventForSessionNotContinued = () => {
  const caChatConfig = getCAConfig();
  if (caChatConfig.checkBusinessHour) {
    isChatEnabled().then((channelProviderData) => {
      if (isHelpPanelAnywherePage()) {
        sendEventToHelpAnywherePanel('initiate', { channelProviderData: JSON.stringify(channelProviderData) });
      }
    });
  }
};

const sendUserDetailEventToHelpPanel = (isLoggedIn, firstName = null, lastName = null, email = null) => {
  if (isHelpPanelAnywherePage() && !isCASession()) {
    let userInfo = {
      firstName: firstName,
      lastName: lastName,
      isUserLoggedIn: isLoggedIn,
      email: email,
    };
    sendEventToHelpAnywherePanel('userDetail', userInfo);
  }
};

const getCookieValue = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
};

function setCookie(cname, cvalue, exMins) {
  var d = new Date();
  d.setTime(d.getTime() + exMins * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
// const isDiscoveryPage = () => {
//   const isClothingBagsPage = window.location.href.includes(getTrans('clothingBags'));
//   const isPromotionalProductPage = window.location.href.includes(getTrans('promotionalProduct'));
//   return isClothingBagsPage || isPromotionalProductPage;
// };

export const handleOfflineChat = () => {
  if (isPPAGLearnPage() || isPPAGFreeSamples()) {
    updatePPAGInlineButton('Start chat', true, isPPAGLearnPage(), isPPAGFreeSamples());
  }
  if (isSearchOrDesignPage().isSearchOrDesignPage) {
    hideSearchOrDesignHelpButton();
  }
  hideCAButton();
};

const createButton = async () => {
  //Get the CTA layout based on the Page URL
  const { buttonHtml } = getButtonHTML();
  const root = document.createElement('div');
  root.setAttribute('id', 'caRootDiv');
  root.classList.add('swan');
  if (document.getElementById('caRootDiv') === null) document.body.appendChild(root);
  root.innerHTML = buttonHtml;
  const isSearchOrDesignPageValue = isSearchOrDesignPage().isSearchOrDesignPage;
  // Adding listerner to parent div of CA button
  addRootDivListeners();
  // Check for Non-Business US hours
  const caChatConfig = getCAConfig();
  if (caChatConfig.checkBusinessHour) {
    isChatEnabled().then((channelProviderData) => {
      const statusObject = channelProviderData.status;
      const gcpRequestId = channelProviderData.gcpRequestId;
      const responseStatus = channelProviderData.responseStatus;
      const requestURL = channelProviderData.requestURL;
      const chatStatus = channelProviderData.status.isChatEnabled;

      /** Time after which chat will go offline */
      const remainingTime = channelProviderData?.status?.remainingTime;
      /** Time at which we'll add the code to remove the chat button */
      const minimumTime = 600;
      try {
        /** For pages where chat button is visible, if the chat is online */
        if (showChatChipOnInit() && !isCASession() && chatStatus) {
          /** If the remaining time is less than 10 mins */
          if (typeof remainingTime === 'number' && remainingTime && remainingTime <= minimumTime) {
            console.log('Chat will be closed after', remainingTime, 'secs');
            logNR('CA_BUSINESS_HR_END', { remainingTime });
            /** add a timeout fn which will remove button after remainingTime is elapsed */
            setTimeout(() => {
              // Check again if session is active or not
              if (!isCASession()) {
                logNR('CA_HIDE_ON_BUSINESS_HR_END');
                if (isHelpCenterHomepage()) {
                  /** SEnd event to HC project */
                  eventBus.dispatch('CAchatOffline', {});
                } else if (isSearchOrDesignPage().isSearchOrDesignPage) {
                  /** Remove button from design page */
                  hideSearchOrDesignHelpButton();
                } else if (isPPAGLearnPage() || isPPAGFreeSamples()) {
                  /** disable ppag chat button */
                  updatePPAGInlineButton('Start chat', true, isPPAGLearnPage(), isPPAGFreeSamples());
                }
                /** Hide CA button */
                hideCAButton();
                /** in case minimized button is visible, hide it */
                const minimizedButton = document.getElementsByClassName('cMinimizedChatCA')[0];
                if (minimizedButton) {
                  (minimizedButton as HTMLButtonElement).style.display = 'none';
                }
              }
            }, remainingTime * 1000);
          }
        }
      } catch (err) {
        console.log(err);
        logNR('CA_ERROR', { errorMessage: 'Error while computing businesss hour end logic' });
      }
      // if (caChatConfig.enableExperimentation) {
      //   const variationKey = getStatsigVariation();
      //   let isAutomatedInviteSend = getCookieValue('CAautomatedInvite');
      //   if (!isAutomatedInviteSend && isAutomatedInvitePage() && variationKey) {
      //     root.innerHTML +=
      //       variationKey == caChatConfig.statSigVariationKey ? invitationsHTML : invitationsHTMLWithoutButton;
      //     addInviteMutationObserver();
      //   }
      // }

      const apiSuccess = channelProviderData.apiSuccess;
      isChatEnabledFromChannelProvider = chatStatus;
      console.log(chatStatus);
      const isHelpPage = isHelpCenterHomepage();

      if (isPPAGLearnPage() || isPPAGFreeSamples()) {
        updatePPAGInlineButton('Preparing chat..', true, isPPAGLearnPage(), isPPAGFreeSamples());
      }
      if (
        chatStatus &&
        showChatChipOnInit() &&
        !isHelpPage &&
        !isSearchOrDesignPageValue &&
        !isStudioPage() &&
        !isPPAGLearnPage()
      ) {
        showCAButton(true);
      } else {
        if (!chatStatus && (isPPAGLearnPage() || isPPAGFreeSamples())) {
          updatePPAGInlineButton('Start chat', true, isPPAGLearnPage(), isPPAGFreeSamples());
        }
        if (!chatStatus && isSearchOrDesignPageValue) {
          hideSearchOrDesignHelpButton();
        }
        hideCAButton();
        if (apiSuccess && !chatStatus && showChatChipOnInit())
          logNR('CA_OUTSIDE_BUSINESS_HR', { gcpResponse: statusObject, gcpRequestId, requestURL, responseStatus });
      }
    });
  }
  const { env } = getCAConfig();
  if (env?.includes('develop') || env?.includes('sandbox')) {
    console.log('test env');
    const isHelpPage = isHelpCenterHomepage();

    if (isPPAGLearnPage() || isPPAGFreeSamples()) {
      updatePPAGInlineButton('Preparing chat..', true, isPPAGLearnPage(), isPPAGFreeSamples());
    }
    if (showChatChipOnInit() && !isHelpPage && !isSearchOrDesignPageValue && !isStudioPage() && !isPPAGLearnPage()) {
      /** Fire Impression for CA Enabled version after business hour check */
      // if (caChatConfig.enableExperimentation && !isAutomatedInvitePage()) fireStatsigImpression();
      showCAButton(true);
    }
  }
  const { sfProperties } = await getCTAForPageUrl();
  initChat(sfProperties);
  const customButton = document.getElementById('caHelpButton');

  // Resolving conflict with chat button and filters in discovery pages Mobile
  if (isMobileOrTablet() && customButton != null && customButton.classList.contains('caHelpButtonEnabled')) {
    const mobileFilterButton = document.getElementById('mobile-button-filter');
    const closeButton = document.getElementById('mobile-filter-header-close-x');
    const resultsButton = document.getElementById('mobile-filter-footer-close-button');

    const showCustomButton = (isVisible) => {
      customButton.style.visibility = isVisible ? 'visible' : 'hidden';
    };

    mobileFilterButton?.addEventListener('click', () => showCustomButton(false));
    closeButton?.addEventListener('click', () => showCustomButton(true));
    resultsButton?.addEventListener('click', () => showCustomButton(true));
  }
  // Resolving conflict with chat button PPAG Mobile

  const helpButtonPPAG = document.querySelector('.caHelpButtonEnabled') as HTMLElement;
  const googleIframe = document.querySelector('#credential_picker_iframe') as HTMLIFrameElement;
  const caStoredPlacement = localStorage.getItem('caBottomPlacement');
  if (
    isMobileOrTablet() &&
    caStoredPlacement &&
    customButton != null &&
    customButton.classList.contains('caHelpButtonEnabled') &&
    helpButtonPPAG
  ) {
    console.log('caStoredPlacement ---> ', caStoredPlacement);
    helpButtonPPAG.style.marginBottom = caStoredPlacement;
    localStorage.removeItem('caBottomPlacement');
  }

  if (isMobileOrTablet() && customButton && googleIframe) customButton.style.zIndex = '1';

  // Mailing Review page
  const isMailingReviewPage = window.location.href.includes(getTrans('mailing-service-review'));
  if (isMailingReviewPage) {
    (customButton as HTMLButtonElement)?.classList.add('conflictingMailingReviewPage');
  }

  // Mailing Landing page
  const isMailingLandingPage = window.location.href.includes(getTrans('mailing-landing'));
  if (isMailingLandingPage) {
    (customButton as HTMLButtonElement)?.classList.add('conflictingMailingLandingPage');
  }
  const chatButton = document.getElementById('caHelpButton');
  if (chatButton) {
    if (isPageConflicting()) {
      conflictingPageMapping.map((ele) => {
        addConflictingPageCSSClass(chatButton, ele.pageName, ele.className);
      });
    }
  }

  (customButton as HTMLButtonElement)?.addEventListener('click', () => {
    console.log('user clicked chat chip');
    let enableDraggable = caChatConfig.enableDraggable;
    if (enableDraggable) {
      if (window.embedded_svc.isCACTAClicked === true) {
        onCAButtonClicked();
      } else {
        window.embedded_svc.isCACTAClicked = true;
      }
    } else {
      onCAButtonClicked();
    }
    // Tracking CA button click on disable state VIR-1762
    const isCAButtonDisabled = (customButton as HTMLButtonElement)?.disabled;
    if (isCAButtonDisabled) logNR('CA_DISABLED_CLICK');
  });
};
const scriptLoad = () => {
  createButton();
  eventBus.on('initChat', (data) => _initChatByEvent(data));
  eventBus.on('onCAButtonClicked', (data) => _cutomButtonClicked(data));
};

const isPageConflicting = () => {
  const pageUrl = window.location.href;
  return conflictingPageMapping.find((ele) => pageUrl.includes(getTrans(ele.pageName))) ? true : false;
};

const isMobilePageConflicting = () => {
  const pageUrl = window.location.href;
  return Object.keys(conflictingMobilePageMapping).find((ele) => pageUrl.includes(getTrans(ele))) ? true : false;
};

const experimentClickedEvent = (experimentDetail: string, experimentKey: string) => {
  let variationKey = getStatsigVariation(experimentKey);
  if (experimentKey) variationKey = getVariation(experimentKey);

  let pageLoadEvent = window.dataLayer?.find(
    (val) => val?.event === 'Product List Viewed' || val?.event === 'Loaded a Page',
  );
  if (variationKey) {
    let pageSection = pageLoadEvent?.pageSection || 'Others';
    let pageName = pageLoadEvent?.pageName || 'Others';
    let pageStage = pageLoadEvent?.pageStage || 'Others';
    let label = (pageLoadEvent?.pageSection || 'Others') + ' view';
    if (window.location.href.includes('/co/')) {
      pageStage = 'Shipping';
      pageSection = 'Checkout';
      pageName = 'Checkout:Shipping';
      label = 'Checkout view';
    }
    AnalyticsTracking.trackChatEvent('GA', 'Experiment Clicked', {
      category: 'Global Chat',
      label,
      pageName,
      pageSection,
      pageStage,
      trackingTenant: 'vistaprint',
      experimentId: experimentKey,
      experimentName: experimentKey,
      variationId: variationKey,
      variationName: variationKey,
      product_id: pageLoadEvent?.product_id || '',
      name: pageLoadEvent?.name || '',
      core_product_id: pageLoadEvent?.core_product_id || '',
      core_product_version: pageLoadEvent?.core_product_version || 0,
      experimentDetail,
    });
  }
};

const enhancedPreChatPages = () => {
  const pageURL = window.location.href;
  const caChatConfig = getCAConfig();
  const currentLocale = caChatConfig.locale.toLowerCase();
  const enhancedPreChatLocale = [
    'en-us',
    'en-gb',
    'en-ie',
    'en-ca',
    'en-au',
    'en-nz',
    'en-sg',
    'da-dk',
    'de-at',
    'de-ch',
    'de-de',
    'es-es',
    'es-us',
    'fi-fi',
    'fr-be',
    'fr-ca',
    'fr-ch',
    'fr-fr',
    'it-ch',
    'it-it',
    'nb-no',
    'sv-se',
    'nl-nl',
    'nl-be',
  ];
  return pageURL.includes(getTrans('helpCenter')) && enhancedPreChatLocale.includes(currentLocale)
    ? 'enhancedPrechatForm'
    : '';
};

const sendAuthTokenForMyProjectDetails = () => {
  setTimeout(() => {
    if (window.location.href.includes(getTrans('helpCenter'))) {
      if (
        window.vp &&
        window.vp.auth &&
        window.vp.auth.cache &&
        window.vp.auth.cache.accessToken &&
        window.vp.auth.cache.isSignedIn
      ) {
        window.embedded_svc.liveAgentAPI.sendCustomEvent(
          'Customer_Access_Token_For_API_call',
          window.vp.auth.cache.accessToken,
        );
      }
    }
  }, 10000);
};
export const detectBrowser = () => {
  const browsers = [
    { name: 'Microsoft Edge', keyword: 'Edg' },
    { name: 'Chrome', keyword: 'Chrome' },
    { name: 'Firefox', keyword: 'Firefox' },
    { name: 'Safari', keyword: 'Safari' },
    { name: 'Opera', keyword: 'Opera' },
    { name: 'Internet Explorer', keyword: 'Trident' },
    { name: 'Internet Explorer', keyword: 'MSIE' },
  ];

  const userAgent = navigator.userAgent;

  for (let i = 0; i < browsers.length; i++) {
    if (userAgent.indexOf(browsers[i].keyword) > -1) {
      return browsers[i].name;
    }
  }

  return 'Unknown';
};

export {
  scriptLoad,
  isPageConflicting,
  isChatEnabledFromChannelProvider,
  hideCAButton,
  showCAButton,
  isFirefox,
  isMobilePageConflicting,
  playNotification,
  isCASession,
  sendEventToHelpAnywherePanel,
  sendEventForSessionNotContinued,
  isHelpPanelAnywherePage,
  sendUserDetailEventToHelpPanel,
  experimentClickedEvent,
  enhancedPreChatPages,
  sendAuthTokenForMyProjectDetails,
  setCookie,
  getCookieValue,
};
