import { getCAConfig } from '../../utils/ca-env';
import languageMapping from '../../utils/languageMapping';

export const pageUrlMapping = () => {
  const caChatConfig = getCAConfig();
  const { tenant } = caChatConfig;
  let data: {
    [x: string]: {
      sfProperties: {
        pageName: string;
        pageURL: string;
        category: string;
        subCategory: string;
        priority?: string;
        issueCategory?: string;
        subCategoryProAdv?: string;
      };
      additionalProperties?: { buttonId?: string; ctaPosition?: string };
    };
  } = {
    Others: {
      sfProperties: {
        pageName: 'Others',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    'Home Page': {
      sfProperties: {
        pageName: 'Home Page',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    businesscards: {
      sfProperties: {
        pageName: 'Business Cards',
        pageURL: window.location.href,
        category: 'Business Cards',
        subCategory: '#Other',
      },
    },
    Offers: {
      sfProperties: {
        pageName: 'Offers',
        pageURL: window.location.href,
        category: 'Consumer',
        subCategory: '#Other',
      },
    },
    christmascards: {
      sfProperties: {
        pageName: 'Holiday: Christmas Cards',
        pageURL: window.location.href,
        category: 'Business Cards',
        subCategory: '#Other',
      },
    },
    photogifts: {
      sfProperties: {
        pageName: 'Photo Gifts',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    businessholiday: {
      sfProperties: {
        pageName: 'Business Holiday Shop',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    signage: {
      sfProperties: {
        pageName: 'Consumer Signage',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    msp: {
      sfProperties: {
        pageName: 'MSP',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.mspButtonId,
      },
    },
    invitations: {
      sfProperties: {
        pageName: 'Stationery',
        pageURL: window.location.href,
        category: 'Signage',
        subCategory: '#Other',
      },
    },
    stationery: {
      sfProperties: {
        pageName: 'Stationery',
        pageURL: window.location.href,
        category: 'Signage',
        subCategory: '#Other',
      },
    },
    'envelope-recipient-addressing': {
      sfProperties: {
        pageName: 'Envelope Recipient Addressing',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    'content hub': {
      sfProperties: {
        pageName: 'Hub',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
    },
    marketingmaterials: {
      sfProperties: {
        pageName: 'Marketing Materials',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
    },
    promotionalproducts: {
      sfProperties: {
        pageName: 'Promotional Products',
        pageURL: window.location.href,
        category: 'PPAG',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.ppagButtonId,
      },
    },
    clothingandbags: {
      sfProperties: {
        pageName: 'Clothing Bags',
        pageURL: window.location.href,
        category: 'Consumer',
        subCategory: '#Other',
      },
    },
    packaging: {
      sfProperties: {
        pageName: 'Packaging',
        pageURL: window.location.href,
        category: 'Packaging & Long Tail',
        subCategory: '#Other',
      },
    },
    samplekit: {
      sfProperties: {
        pageName: 'Sample Kits',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    reseller: {
      sfProperties: {
        pageName: 'Reseller',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    signs: {
      sfProperties: {
        pageName: 'Signs Posters',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
    },
    posters: {
      sfProperties: {
        pageName: 'Signs Posters',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
    },
    banners: {
      sfProperties: {
        pageName: 'Signs Posters',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
    },
    labels: {
      sfProperties: {
        pageName: 'Labels Stickers',
        pageURL: window.location.href,
        category: 'Packaging & Long Tail',
        subCategory: '#Other',
      },
    },
    stickers: {
      sfProperties: {
        pageName: 'Labels Stickers',
        pageURL: window.location.href,
        category: 'Packaging & Long Tail',
        subCategory: '#Other',
      },
    },
    digitalmarketing: {
      sfProperties: {
        pageName: 'Digital Marketing',
        pageURL: window.location.href,
        category: 'WIX Product',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.digitalButtonId,
      },
    },
    studio: {
      sfProperties: {
        pageName: 'Studio',
        pageURL: window.location.href,
        category: 'Studio / Product-Design Configuration',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },
    search: {
      sfProperties: {
        pageName: 'Search Page: No Results',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    DesignServices: {
      sfProperties: {
        pageName: 'Design',
        pageURL: window.location.href,
        category: 'Studio / Product-Design configuration',
        subCategory: 'Design Services',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },
    'design services:dashboard': {
      sfProperties: {
        pageName: 'Design Services:Dashboard',
        pageURL: window.location.href,
        category: 'Studio / Product-Design configuration',
        subCategory: '#Other',
      },
    },
    'logos:design': {
      sfProperties: {
        pageName: 'Logo Design',
        pageURL: window.location.href,
        category: 'Studio / Product-Design configuration',
        subCategory: 'Design Services',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },
    'page:design': {
      sfProperties: {
        pageName: 'Logo Maker',
        pageURL: window.location.href,
        category: 'Studio / Product-Design configuration',
        subCategory: 'Design Services',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },
    qrcodegenerator: {
      sfProperties: {
        pageName: 'QR Code Generator',
        pageURL: window.location.href,
        category: 'Studio / Product-Design configuration',
        subCategory: 'Design Services',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },
    newarrivals: {
      sfProperties: {
        pageName: 'New Arrivals',
        pageURL: window.location.href,
        category: 'Consumer',
        subCategory: '#Other',
      },
    },
    gallery: {
      sfProperties: {
        pageName: 'Gallery',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
    },
    pdc: {
      sfProperties: {
        pageName: 'PDC',
        pageURL: window.location.href,
        category: 'Business Cards',
        subCategory: '#Other',
      },
      additionalProperties: {
        ctaPosition: 'conflicting',
      },
    },
    upSellCrossSell: {
      sfProperties: {
        pageName: 'upSellCrossSell',
        pageURL: window.location.href,
        category: 'Buy / Checkout / Payment',
        subCategory: '#Other',
      },
      additionalProperties: {
        ctaPosition: 'conflictingXS',
      },
    },
    galleryDesignServices: {
      sfProperties: {
        pageName: 'Gallery Design Services',
        pageURL: window.location.href,
        category: 'Studio / Product-Design Configuration',
        subCategory: 'Design Services',
      },
      additionalProperties: {
        ctaPosition: 'conflictingLogoMaker',
        buttonId: caChatConfig.designButtonId,
      },
    },

    'page:en-us:design': {
      sfProperties: {
        pageName: 'Logo Maker',
        pageURL: window.location.href,
        category: 'Studio / Product-Design configuration',
        subCategory: 'Design Services',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },

    graphicDesign: {
      sfProperties: {
        pageName: 'Graphic Design',
        pageURL: window.location.href,
        category: 'Studio / Product-Design Configuration',
        subCategory: 'Design Services',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },

    consumerholidayhub: {
      sfProperties: {
        pageName: 'Personalized Christams Gifts',
        pageURL: window.location.href,
        category: 'Marketing Materials',
        subCategory: '#Other',
      },
    },
    payment: {
      sfProperties: {
        pageName: 'My Account: Payment & Shipping',
        pageURL: window.location.href,
        category: 'Buy/Checkout/Payment',
        subCategory: '#Other',
      },
    },
    shipping: {
      sfProperties: {
        pageName: 'Shipping',
        pageURL: window.location.href,
        category: 'Delivery/Packaging',
        subCategory: '#Other',
      },
    },
    checkoutShipping: {
      sfProperties: {
        pageName: 'Checkout:Shipping',
        pageURL: window.location.href,
        category: 'Buy/Checkout/Payment',
        subCategory: '#Other',
        priority: 'High',
      },
      additionalProperties: {
        buttonId: caChatConfig.checkoutButtonId,
      },
    },
    checkoutPayment: {
      sfProperties: {
        pageName: 'Checkout:Payment',
        pageURL: window.location.href,
        category: 'Buy/Checkout/Payment',
        subCategory: '#Other',
        priority: 'High',
      },
      additionalProperties: {
        buttonId: caChatConfig.checkoutButtonId,
      },
    },
    cart: {
      sfProperties: {
        pageName: 'Cart',
        pageURL: window.location.href,
        category: 'Buy/Checkout/Payment',
        subCategory: 'Checkout problems',
        priority: 'High',
      },
      additionalProperties: {
        buttonId: caChatConfig.checkoutButtonId,
      },
    },
    productList: {
      sfProperties: {
        pageName: 'All Products',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    satisfaction: {
      sfProperties: {
        pageName: 'Satisfaction Guarantee',
        pageURL: window.location.href,
        category: 'Product Quality/Post Order Issue',
        subCategory: '#Other',
      },
    },
    aboutUs: {
      sfProperties: {
        pageName: 'About Us',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    optOut: {
      sfProperties: {
        pageName: 'Right To Opt Out',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    privacyPolicy: {
      sfProperties: {
        pageName: 'Privacy Policy',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    termsOfUse: {
      sfProperties: {
        pageName: 'Terms Of Use',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    legalNotice: {
      sfProperties: {
        pageName: 'Legal Notice',
        pageURL: window.location.href,
        category: '#Other',
        subCategory: '#Other',
      },
    },
    orderHistory: {
      sfProperties: {
        pageName: 'My Account: Order History',
        pageURL: window.location.href,
        category: 'My projects/order history/reordering',
        subCategory: 'Access order history',
      },
    },
    myProjects: {
      sfProperties: {
        pageName: 'My Account: Projects',
        pageURL: window.location.href,
        category: 'My projects/order history/reordering',
        subCategory: 'Access past projects',
      },
      additionalProperties: {
        buttonId: caChatConfig.designButtonId,
      },
    },
    myUploads: {
      sfProperties: {
        pageName: 'My Account: My Uploads',
        pageURL: window.location.href,
        category: 'My projects/order history/reordering',
        subCategory: '#Other',
      },
    },
    workspace: {
      sfProperties: {
        pageName: 'My Account: Digital Marketing',
        pageURL: window.location.href,
        category: 'Digital',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.digitalButtonId,
      },
    },
    paymentAndShipping: {
      sfProperties: {
        pageName: 'My Account: Payment & Shipping',
        pageURL: window.location.href,
        category: 'Buy/Checkout/Payment',
        subCategory: '#Other',
      },
    },
    accountSettings: {
      sfProperties: {
        pageName: 'My Account: Account Settings',
        pageURL: window.location.href,
        category: 'Customer Account',
        subCategory: 'Account Settings',
      },
    },
    subscription: {
      sfProperties: {
        pageName: 'My Account: Subscriptions',
        pageURL: window.location.href,
        category: 'Customer Account',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.digitalButtonId,
      },
    },
    brandKit: {
      sfProperties: {
        pageName: 'My Account: Brand Kit',
        pageURL: window.location.href,
        category: 'Customer Account',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.digitalButtonId,
      },
    },
    mailingKit: {
      sfProperties: {
        pageName: 'My Account: Mailing Lists',
        pageURL: window.location.href,
        category: 'Customer Account',
        subCategory: '#Other',
      },
      additionalProperties: {
        buttonId: caChatConfig.mspButtonId,
      },
    },
    favorites: {
      sfProperties: {
        pageName: 'Gallery: Favourite Templates',
        pageURL: window.location.href,
        category: 'Customer Account',
        subCategory: '#Other',
      },
    },
    ppag: {
      sfProperties: {
        pageName: 'Quantity Selection',
        pageURL: window.location.href,
        category: 'PPAG',
        subCategory: '#Other',
      },
      additionalProperties: {
        ctaPosition: 'conflictingPpag',
      },
    },
    apdc: {
      sfProperties: {
        pageName: 'Apdc',
        pageURL: window.location.href,
        category: 'Buy / Checkout / Payment',
        subCategory: '#Other',
      },
      additionalProperties: {
        ctaPosition: 'conflictingApdc',
      },
    },
    findMyOrder: {
      sfProperties: {
        pageName: 'My Account:Find My Order',
        pageURL: window.location.href,
        category: 'Delivery/packaging',
        subCategory: 'Order Status Tracking',
      },
    },
    helpCenter: {
      sfProperties: {
        pageName: 'Help Center',
        pageURL: window.location.href,
        category: 'Product Quality/Post Order Issue',
        subCategory: '#Other',
      },
    },
    thankyoucards: {
      sfProperties: {
        pageName: 'Stationery: Thank You Cards',
        pageURL: window.location.href,
        category: 'Signage',
        subCategory: '#Other',
      },
    },
    vistaConnect: {
      sfProperties: {
        pageName: 'Vista Connect',
        pageURL: window.location.href,
        category: 'Vista Connect',
        subCategory: '#Other',
      },
      additionalProperties: {
        ctaPosition: 'conflictingApdc',
      },
    },
  };

  if (tenant === 'vcs') {
    data['Home Page'].sfProperties.issueCategory = 'Assist with Edits';
    data['Home Page'].sfProperties.subCategoryProAdv = 'Assist with Edits';

    data['businesscards'].sfProperties.issueCategory = 'Assist with Edits';
    data['businesscards'].sfProperties.subCategoryProAdv = 'Assist with Edits';

    data['reseller'].sfProperties.issueCategory = 'Membership';
    data['reseller'].sfProperties.subCategoryProAdv = 'Reactivate Membership';

    data['digitalmarketing'].sfProperties.issueCategory = 'Membership';
    data['digitalmarketing'].sfProperties.subCategoryProAdv = 'Reactivate Membership';

    data['DesignServices'].sfProperties.issueCategory = 'Assist with Edits';
    data['DesignServices'].sfProperties.subCategoryProAdv = 'Assist with Edits';

    data['checkoutShipping'].sfProperties.issueCategory = 'Checkout/Order Assist';
    data['checkoutShipping'].sfProperties.subCategoryProAdv = 'Checkout';

    data['checkoutPayment'].sfProperties.issueCategory = 'Checkout/Order Assist';
    data['checkoutPayment'].sfProperties.subCategoryProAdv = 'Checkout';

    data['cart'].sfProperties.issueCategory = 'Checkout/Order Assist';
    data['cart'].sfProperties.subCategoryProAdv = 'Cart';

    data['orderHistory'].sfProperties.issueCategory = 'Order Status';
    data['orderHistory'].sfProperties.subCategoryProAdv = 'Tracking';

    data['myProjects'].sfProperties.issueCategory = 'Assist with Edits';
    data['myProjects'].sfProperties.subCategoryProAdv = 'Assist with Edits';

    data['workspace'].sfProperties.issueCategory = 'Membership';
    data['workspace'].sfProperties.subCategoryProAdv = 'Reactivate Membership';

    data['subscription'].sfProperties.issueCategory = 'Membership';
    data['subscription'].sfProperties.subCategoryProAdv = 'Reactivate Membership';

    data['helpCenter'].sfProperties.issueCategory = 'General Information';
    data['helpCenter'].sfProperties.subCategoryProAdv = 'No Response';
  }
  return data;
};

export const getContext = () => ({
  chatLocale: {
    label: 'Chat Locale',
    value: languageMapping(),
    transcriptFields: ['ChatLocale__c'],
    displayToAgent: true,
  },
  brand: {
    label: 'Brand',
    transcriptFields: [],
    value: 'VP',
    displayToAgent: true,
  },
  priority: {
    label: 'Priority',
    value: 'Medium',
    transcriptFields: [],
    displayToAgent: true,
  },
  pageName: {
    label: 'Page Name',
    transcriptFields: ['Page_Name__c'],
    displayToAgent: true,
  },
  pageURL: {
    label: 'Page URL',
    transcriptFields: ['Page_URL__c'],
    displayToAgent: true,
  },
  category: {
    label: 'Category',
    transcriptFields: ['Category__c'],
    displayToAgent: true,
  },
  subCategory: {
    label: 'Sub Category',
    transcriptFields: ['Sub_Category__c'],
    displayToAgent: true,
  },
  caseOrigin: {
    label: 'Origin',
    transcriptFields: [],
    displayToAgent: true,
  },
  subject: {
    label: 'issue',
    transcriptFields: [],
    displayToAgent: true,
  },
  description: {
    label: 'Description',
    transcriptFields: [],
    displayToAgent: true,
  },
  status: {
    label: 'Status',
    value: 'In Progress',
    transcriptFields: [],
    displayToAgent: true,
  },
  banner: {
    label: 'Banner',
    value: 'VP',
    transcriptFields: [],
    displayToAgent: true,
  },
  pageSection: {
    label: 'pageSection',
  },
  pageStage: {
    label: 'pageStage',
  },
  pagePath: {
    label: 'pagePath',
  },
});

export const pageNames = [
  { 'home page': 'Home Page' },
  { 'special offers': 'Offers' },
  {
    'landing page:invitations & stationery:/indexable/en-us/envelope-recipient-addressing:multi product':
      'envelope-recipient-addressing',
  },
  { 'landing page:proadvantage': 'reseller' },
  { 'homepage:vista logos:design services': 'DesignServices' },
  { 'shipping and delivery information | vistaprint': 'shipping' },
  { 'all products:product list': 'productList' },
  { 'checkout:cart': 'cart' },
  { 'checkout:shipping': 'checkoutShipping' },
  { 'checkout:payment methods': 'checkoutPayment' },
  { "vistaprint's satisfaction guarantee": 'satisfaction' },
  { 'who we are & about us | vistaprint': 'aboutUs' },
  { 'notice of right to opt out': 'optOut' },
  { 'vistaprint privacy policy': 'privacyPolicy' },
  { 'terms of use | vistaprint': 'termsOfUse' },
  { 'legal notice | vistaprint': 'legalNotice' },
  { 'my account:order history': 'orderHistory' },
  { 'my account:my projects': 'myProjects' },
  { 'my account:my uploads': 'myUploads' },
  { 'digital marketing:workspace dashboard:manage': 'workspace' },
  { 'my account:payment and address details': 'paymentAndShipping' },
  { 'my account:account settings': 'accountSettings' },
  { 'subscriptions settings:manage': 'subscription' },
  { 'my account:brand kit main': 'brandKit' },
  { 'my account:brand kit management': 'brandKit' },
  { 'my account:mailing list': 'mailingKit' },
  { 'my account:favorites': 'favorites' },
  { 'design services: hub page': 'DesignServices' },
  { 'design services:hub page': 'DesignServices' },
  { 'design services:expert services brief': 'DesignServices' },
  { 'design services: expert services brief': 'DesignServices' },
  { 'home page:content hub': 'content hub' },
  { 'my account:find my order': 'findMyOrder' },
  { 'my account:buy it again': 'orderHistory' },
];

// export const conflictingPageMapping = {
//   "conflictingCta":[
//     '/pdc/'
//   ],
//   "conflictingLogoMaker":[
//     '/logomaker/studio/graphics'
//   ],
//   "conflictingPpag":[
//     '/configure/quantity?',
//   ],
//   "conflictingXS":[
//     '/xs/',
//     '/acc/',
//     '/vxw-xs/',
//     '/env/'
//   ],
//   "conflictingApdc":[
//     '/apdc/',
//   ],
//   "conflictingChristmasCards":[
//     '/holiday/christmas-cards'
//   ]

// }

export const conflictingPageMapping = [
  { pageName: '/pdc/', className: 'conflictingApdc' },
  { pageName: '/logomaker', className: 'conflictingLogoMaker' },
  { pageName: '/configure/quantity?', className: 'conflictingPpag' },
  { pageName: '/xs/', className: 'conflictingXS' },
  { pageName: '/acc/', className: 'conflictingXS' },
  { pageName: '/vxw-xs/', className: 'conflictingXS' },
  { pageName: '/env/', className: 'conflictingXS' },
  { pageName: '/apdc/', className: 'conflictingApdc' },
  { pageName: '/ppag-studio/', className: 'conflictingPpag' },
  { pageName: 'reseller', className: 'conflictingCta' },
  { pageName: 'configure/review', className: 'conflictingCta' },
  { pageName: '/upload-and-print/', className: 'conflictingCta' },
  { pageName: '/business-cards-connect/', className: 'conflictingCta' },
  { pageName: '/packaging-studio/', className: 'conflictingCta' },
  { pageName: '/quick-upload-and-design/', className: 'conflictingCta' },
  { pageName: 'sample-kits', className: 'conflictingSampleKits' },
  { pageName: 'mailing-landing', className: 'conflictingMailingLandingPage' },
];
export const conflictingMobilePageMapping = {
  'business-cards': 'conflictingBrowseDesignBtnPage',
  'marketing-materials': 'conflictingBrowseDesignBtnPage',
  'signs-posters': 'conflictingBrowseDesignBtnPage',
  'custom-stationery': 'conflictingBrowseDesignBtnPage',
  'custom-packaging': 'conflictingBrowseDesignBtnPage',
  'labels-stickers': 'conflictingBrowseDesignBtnPage',
  'photo-gifts/calendars': 'conflictingCalendarBtnPage',
  'photo-gifts': 'conflictingBrowseDesignBtnPage',
  'packaging-studio': 'conflictingPackagingStudio',
  'mobile-studio': 'conflictingStudioBtnPage',
  'promotional-products': 'conflictingPromotionalProducts',
  'clothing-bags': 'conflictingPromotionalProducts',
  helpCenter: 'conflictingHelpCenterHomePage',
  studio: 'studio',
};

export const chatChipVisblePages = [
  'cart', // checkout button
  'checkout', // checkout button
  'mailing-service-upload', // msp button
  'mailing-service-upload-old', // old URL msp button
  'my-account-projects', // default button
  'studio', // design button,
  'search', // default button
  'helpCenter',
  'design',
  'mailing-service-review',
  'mailing-service-landing',
  'business-cards-standard',
  'signsPosters',
  'business-cards',
  'marketing-materials',
  'signs-posters',
  'custom-stationery',
  'custom-packaging',
  'labels-stickers',
  'photo-gifts',
  'signs-banners',
  'sample-kits',
  'offersPage',
  'promotionalProduct',
  'clothingBags',
  'gallery',
  'adp',
  'christmasCards',
  'my-account-uploads',
];

export const chatChipNonVisblePages = [
  'experts-services',
  'experts-request',
  'reseller',
  'graphicDesign-Dashboard',
  'qr',
  'logomaker',
  '/acc/',
  '/xs/',
  '/env/',
  '/pdc/',
  '/apdc',
];
export const conflictpageCSSMapping = [
  { pageName: 'ppag-studio', className: 'conflictingPPAGStudio' },
  { pageName: 'upload-and-print', className: 'chatAnywhereConflictingUploadPrint' },
  { pageName: 'logomaker', className: 'conflictingLogoMaker' },
  { pageName: 'packaging-studio', className: 'conflictingPackagingStudio' },
  { pageName: 'quick-upload-and-design', className: 'conflictingQuickUpload' },
  { pageName: 'business-cards', className: 'conflictingBrowseDesignBtnPage' },
  { pageName: 'marketing-materials', className: 'conflictingBrowseDesignBtnPage' },
  { pageName: 'signs-posters', className: 'conflictingBrowseDesignBtnPage' },
  { pageName: 'custom-stationery', className: 'conflictingBrowseDesignBtnPage' },
  { pageName: 'custom-packaging', className: 'conflictingBrowseDesignBtnPage' },
  { pageName: 'labels-stickers', className: 'conflictingBrowseDesignBtnPage' },
  { pageName: 'photo-gifts', className: 'conflictingBrowseDesignBtnPage' },
  { pageName: 'photo-gifts/calendars', className: 'conflictingCalendarBtnPage' },
  { pageName: 'promotionalProduct', className: 'conflictingPromotionalProducts' },
  { pageName: 'clothingBags', className: 'conflictingPromotionalProducts' },
  { pageName: 'mobile-studio', className: 'conflictingStudioBtnPage' },
  { pageName: 'studio', className: 'studioPageChatButton' },
  { pageName: 'helpCenter', className: 'conflictingHelpCenterHomePage' },
  { pageName: 'mailing-service-review', className: 'conflictingMailingReviewPage' },
];

export const helpPanelAnywherePages = ['studio'];

export const automatedInvitePage = ['cart', 'checkout'];
