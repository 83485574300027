import { getCAConfig } from './ca-env';

const isMobileOrTablet = () => {
  // Function to check if the client is Mobile/ Tablet Client
  const userAgent = navigator?.userAgent?.toLowerCase() ?? '';
  const isMobile = /iPhone|Android/i.test(userAgent);
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent,
    );
  return isMobile || isTablet;
};
const isMobile = () => {
  // Function to check if the locale is 'en-US' and the client is Mobile/ Tablet Client
  const isMob = /iPhone|Android/i.test(window.navigator.userAgent);
  return isMob;
};
const extractDomain = () => {
  try {
    const pageURL = window.location.href;
    const pattern = /https?:\/\/(?:www\.)?([^/]+)/;
    const match = pageURL.match(pattern);
    return match ? match[1] : null;
  } catch (error) {
    console.error('Error extracting domain:', error);
    return null;
  }
};
const isEnabledForMobile = () => {
  const { enableForMobile } = getCAConfig();
  return enableForMobile && isMobileOrTablet();
};
export { isEnabledForMobile, isMobileOrTablet, isMobile, extractDomain };
