import { WindowType } from '../types/windowType';
import { Locale, localeURLMap, localesArray } from '../types/app-interfaces';

declare const window: WindowType;

const fetchLocaleFromURL = (): Locale => {
  const pageURL = window.location.href;

  const matchedValue = localeURLMap.find(({ pattern }) => pattern.test(pageURL));
  if (matchedValue) return matchedValue.value;
  /** Default to en-us if no matching pattern found */
  console.error(' No Match for URL and locale found');
  return 'en-us';
};
// Function to check if a value is a valid Locale
export const isLocale = (value?: string): boolean => localesArray.includes(value as Locale);

export const extractLocale = (locale): Locale => {
  try {
    let modifiedLocale: Locale;
    if (isLocale(locale)) {
      return locale;
    }
    if (isLocale(window.CALocale)) {
      return window.CALocale as Locale;
    }
    /** Check Locale */
    if (!locale && !window.CALocale) {
      console.error(' locale missing');
      modifiedLocale = fetchLocaleFromURL();
    } else {
      modifiedLocale = locale.toLowerCase();
      if (!localesArray.includes(locale)) {
        /** Locale not in the list
         * Check for '_' format
         */
        const underScoreRegExp = /^[a-zA-Z]{2}_[a-zA-Z]{2}$/;
        if (underScoreRegExp.test(locale)) {
          modifiedLocale = locale.replace('_', '-');
        } else {
          modifiedLocale = fetchLocaleFromURL();
        }
      }
    }
    return modifiedLocale;
  } catch (e) {
    console.error(e);
    return 'en-us';
  }
};
