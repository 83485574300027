import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { ILogName, logMapping } from '../types/NRAttributestype';
import { getCAConfig } from './ca-env';

const CA_LOG_KEY = 'ca-log-key';
/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    newrelic: any;
  }
}
export const getNRId = () => {
  const id = Cookies.get(CA_LOG_KEY);
  return id;
};

export const setNRId = () => {
  if (!getNRId()) {
    const id = uuidv4();
    Cookies.set(CA_LOG_KEY, id);
  }
};

export const removeNRId = () => {
  Cookies.remove(CA_LOG_KEY);
};

export const initNR = () => {
  setNRId();
};

export const logNR = (logCode: ILogName, additionalInfo?: unknown): any => {
  const { env } = getCAConfig();
  if (env?.includes('production') || env?.includes('sandbox')) {
    let attributes = logMapping(logCode);
    const caLogKey = getNRId();
    if (typeof additionalInfo in ['boolean', 'string', 'number']) {
      attributes = { ...attributes, additionalInfo };
    } else if (typeof additionalInfo === 'object') {
      attributes = { ...attributes, ...additionalInfo };
    }

    console.info(logCode, { attributes }, caLogKey);
    // set the event name in NR
    const pageAction = 'chat_anywhere';
    if (window && window.newrelic) {
      window.newrelic?.addPageAction(pageAction, { ...attributes, caLogKey });
    }
  } else console.info('NR Logging disabled for DEV environment ');
};
