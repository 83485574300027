import Cookies from 'js-cookie';
import { IConfigData } from '../../../types/app-interfaces';
import { sandboxConfig } from './sandboxConfig';
import { devsConfig } from '../devs/devsConfig';

const COOKIE_VALUE = 'cadevs';
const COOKIE_NAME = 'true';

export const getSandboxConfig = (locale: string): IConfigData => {
  if (Cookies.get(COOKIE_NAME) === COOKIE_VALUE) return devsConfig;
  const localeConfig = sandboxConfig[locale];
  return {
    locale,
    tenant: 'vistaprint',
    env: 'sandbox',
    ...localeConfig,
    baseLiveAgentContentURL: 'https://c.la2-c1cs-cdg.salesforceliveagent.com/content',
    eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I07000000Cbg4EAC_185dd6e1140',
    deploymentId: '57207000000Gp73',
    baseLiveAgentURL: 'https://d.la2-c1cs-cdg.salesforceliveagent.com/chat',
    isOfflineSupportEnabled: false,
    baseCoreURL: 'https://vpcare--full.sandbox.my.salesforce.com',
    communityEndpointURL: 'https://vpcare--full.sandbox.my.salesforce-sites.com/public',
    orgID: '00D3N000000HG1U',
    loadLightningScript: true,
    enableDraggable: false,
    addDefaultPageEvent: true,
    gaTrackingEnabled: true,
    segmentTrackingEnabled: false,
    ctaPreLoadEnabled: true,
    enableForMobile: true,
    timeToShowAutomatedInviteInSeconds: 10,
    timeForChatDuplicationCookieExpiry: 10,
  };
};
