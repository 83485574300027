import { isTreatmentEnabled } from '@vp/ctc-reader';
import { logNR } from './newRelic';
/* eslint-disable @typescript-eslint/no-explicit-any */
// export const whenCTCReaderAvailable = (cb) => {
//   let isCallbackInvoked = false;
//   whenAvailable(() => {
//     isCallbackInvoked = true;
//     cb();
//   });

//   /** Invoke callback if ctc not ready after 2 seconds */
//   setTimeout(() => {
//     if (!isCallbackInvoked) {
//       isCallbackInvoked = true;
//       console.warn('CTC library not initialized, invoking Callback');
//       cb();
//     }
//   }, 2000);
// };

const isProAdvantageCustomer = () => {
  try {
    if (!window.location.href.includes('localhost')) {
      console.info('isProAdvantageCustomer fn');
      if (isTreatmentEnabled('proadvantage_basic_care') || isTreatmentEnabled('proadvantage_premium_care')) {
        console.info('Pro Advantage Customer detected');
        return true;
      }
    }
    return false;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    logNR('CA_ERROR', { errorMessage: error.message, errorStack: error.stack });
  }
  return false;
};

export { isProAdvantageCustomer };
