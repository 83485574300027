import { WindowType } from '../types/windowType';
import { getTrans } from '../CACTA/JSComponents/JS/translations';
import { getCAConfig } from '../utils/ca-env';

declare const window: WindowType;

const isSearchOrDesignPage = () => {
  const caChatConfig = getCAConfig();
  const path = window.location.pathname;
  const { locale } = caChatConfig;
  const notAllowedLocales = [
    'en-us',
    'en-ca',
    'en-au',
    'en-nz',
    'en-sg',
    'it-ch',
    'it-it',
    'sv-se',
    'fi-fi',
    'nb-no',
    'es-es',
    'es-us',
    'nl-nl',
    'nl-be',
    'da-dk',
    'fr-be',
    'fr-fr',
    'fr-ch',
    'de-at',
    'de-ch',
    'en-ie',
    'en-gb',
    'de-de',
    'fr-ca',
  ];

  if (path.includes(getTrans('search')) && !notAllowedLocales.includes(locale)) {
    return { isSearchOrDesignPage: true, pageName: 'Search' };
  }
  if (path.includes(getTrans('my-account-projects')) || path.includes(getTrans('my-account-uploads'))) {
    return { isSearchOrDesignPage: false, pageName: '' };
  }
  if (path.includes(getTrans('design')) || path.includes('/design')) {
    return { isSearchOrDesignPage: true, pageName: 'Design' };
  }
  return { isSearchOrDesignPage: false, pageName: '' };
};

const updateStudioButton = (enableButton: boolean) => {
  if (enableButton) {
    const helpButtonElement = document.querySelector('[data-testid="designHelp"]') as HTMLButtonElement;
    if (helpButtonElement) helpButtonElement.style.display = 'flex';
  } else {
    const helpButtonElement = document.querySelector('[data-testid="designHelp"]') as HTMLButtonElement;
    if (helpButtonElement) helpButtonElement.style.display = 'none';
  }
};

const isStudioButtonEnabled = () => {
  const helpButtonElement = document.querySelector('[data-testid="designHelp"]') as HTMLButtonElement;
  if (helpButtonElement && helpButtonElement.style.display !== 'none') return true;
  return false;
};

const updateButtonState = (innerText: string, disableButton: boolean) => {
  const { pageName } = isSearchOrDesignPage();
  const buttonName = pageName === 'Search' ? 'liveChatButton' : 'onlineBtn';
  const startButton = document.getElementById(buttonName) as HTMLButtonElement;
  const loader = document.getElementById('loader') as HTMLButtonElement;
  if (startButton) {
    // eslint-disable-next-line no-console
    console.log('changing button state', startButton, innerText);
    if (innerText && startButton.childNodes && startButton.childNodes.length) {
      if (startButton.childNodes.length > 1) {
        startButton.childNodes[1].nodeValue = innerText;
      } else startButton.innerText = innerText;
    }
    startButton.disabled = disableButton;
  }
  if (loader) loader.style.display = 'none';
};

const hideSearchOrDesignHelpButton = () => {
  // eslint-disable-next-line no-console
  console.log('hiding search button');
  const { pageName } = isSearchOrDesignPage();
  const buttonName = pageName === 'Search' ? 'liveChatButton' : 'onlineBtn';
  const startButton = document.getElementById(buttonName) as HTMLButtonElement;
  if (startButton) {
    startButton.style.display = 'none';
    startButton.disabled = true;
  }
};

const isPPAGLearnPage = () => {
  const pageURL = window.location.href;
  if (pageURL.includes('discover-and-learn')) return true;
  return false;
};

const isPPAGFreeSamples = () => {
  const pageURL = window.location.href;
  if (pageURL.includes('free-samples')) return true;
  return false;
};

const updatePPAGInlineButton = (
  buttonText: 'Preparing chat..' | 'Start chat' | 'Chatting...',
  disabled: boolean,
  ppagLearnPage: boolean,
  ppagFreeSamples: boolean,
) => {
  console.info('###UPDATE PPAGE');

  const linkDiv = document.querySelector('.swan-banner-links');
  const anchorElement = linkDiv?.childNodes[0];

  /** check if element exists and its chat button */
  if (linkDiv && anchorElement) {
    if (
      (anchorElement as HTMLAnchorElement)?.innerHTML?.includes('Start chat') ||
      (anchorElement as HTMLAnchorElement)?.innerHTML?.includes('Preparing chat..') ||
      (anchorElement as HTMLAnchorElement)?.innerHTML?.includes('Chatting...')
    ) {
      console.info('##PPAG');
      /** Remove HREF to stop redirection */
      (anchorElement as HTMLAnchorElement)?.removeAttribute('href');
      /** Add on click if chat is enabled */
      if (!disabled) {
        (anchorElement as HTMLAnchorElement).onclick = () => {
          window.embedded_svc.onHelpButtonClick();
        };
      } else {
        (anchorElement as HTMLAnchorElement).onclick = () => {
          console.info('PPAG  button disabled');
        };
      }
      /** Update Button text */
      (anchorElement as HTMLAnchorElement).innerText = buttonText;
      /** Update button color as per disable status */
      const styles = (anchorElement as HTMLDivElement).style;
      if (styles && ppagLearnPage) {
        if (disabled) {
          styles.backgroundColor = '#dfdfdf';
          styles.borderColor = '#dfdfdf';
          styles.color = '#9e9e9e';
        } else {
          styles.backgroundColor = '#000000';
          styles.borderColor = '#000000';
          styles.color = '#ffffff';
        }
      }
      if (styles && ppagFreeSamples) {
        if (disabled) {
          styles.backgroundColor = '#dfdfdf';
          styles.borderColor = '#dfdfdf';
          styles.color = '#9e9e9e';
        } else {
          styles.backgroundColor = '#ffffff';
          styles.borderColor = '#000000';
          styles.color = '#000000';
        }
      }
      anchorElement.addEventListener('mouseover', () => {
        if (!disabled && ppagFreeSamples) {
          styles.backgroundColor = '#bcbcbc'; // Example hover color
        }
      });
      anchorElement.addEventListener('mouseout', () => {
        if (!disabled && ppagFreeSamples) {
          styles.backgroundColor = '#ffffff'; // Revert to original color
        }
      });
    }
  }
};

export {
  isSearchOrDesignPage,
  hideSearchOrDesignHelpButton,
  updateStudioButton,
  isStudioButtonEnabled,
  isPPAGLearnPage,
  isPPAGFreeSamples,
  updatePPAGInlineButton,
  updateButtonState,
};
export default updateButtonState;
