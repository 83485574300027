import ContextBuilder from '../CACTA/JSComponents/JS/Context';
import { getCAConfig } from '../utils/ca-env';

var initializeChat = async function (embedded_svc, gslbBaseURL, data) {
  const ctaConfig = await ContextBuilder();
  let btnId = ctaConfig?.additionalProperties?.buttonId;
  const {
    baseCoreURL,
    communityEndpointURL,
    orgID,
    eswLiveAgentDevName,
    baseLiveAgentContentURL,
    deploymentId,
    defaultButtonId,
    baseLiveAgentURL,
    isOfflineSupportEnabled,
    eswConfigDevName,
    tenant,
  } = getCAConfig();

  if (tenant === 'vcs') {
    btnId = defaultButtonId;
  }
  embedded_svc.init(baseCoreURL, communityEndpointURL, gslbBaseURL, orgID, eswConfigDevName, {
    baseLiveAgentContentURL,
    deploymentId,
    buttonId: btnId ? btnId : defaultButtonId,
    baseLiveAgentURL,
    eswLiveAgentDevName,
    isOfflineSupportEnabled,
  });
};
export { initializeChat };
