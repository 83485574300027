import { eventBus } from '../utils/eventBus';
import { overrideChabotConfiguration } from './settingOverride';
import { authenticateUser } from './userAuthentication';
import { isFirefox } from '../CACTA/JSComponents/JS/helper';
import { initiateCAChat } from '../CACTA/JSComponents/JS/eventManagement';
import { getCAConfig } from '../utils/ca-env';

export const _initChatByEvent = (data) => {
  console.log('_initChatByEvent', { data });
  if (typeof window !== 'undefined' && window.document && !window.caSFScript) {
    if (!window.embedded_svc) {
      let scriptURL;
      const { env } = getCAConfig();
      if (env === 'production')
        scriptURL = 'https://chat-anywhere.care.vpsvc.com/caretech-assets/embeddedservice.esw.min.js';
      else
        scriptURL = 'https://chat-anywhere-staging.care-staging.vpsvc.com/caretech-assets/embeddedservice.esw.min.js';
      var s = document.createElement('script');
      // Original caretech CDN url is replaced with CA S3 | VIR-2533
      s.setAttribute('src', scriptURL);
      window.caSFScript = true;
      s.onload = function () {
        window.embedded_svc.isCACTAClicked = true;
        window.embedded_svc.isDragStarted = false;

        initESW(null, data);
      };
      document.body.appendChild(s);
    } else {
      window.embedded_svc.isCACTAClicked = true;
      window.embedded_svc.isDragStarted = false;

      initESW(null, data);
    }
  }
};
export const _cutomButtonClicked = (data) => {
  console.log('_cutomButtonClicked', { data });
  if (window.embedded_svc) embedded_svc.onHelpButtonClick();
  //embedded_svc.bootstrapEmbeddedService()
};

var initESW = async function (gslbBaseURL, data) {
  // Changing gslbaseurl for firefox session continuation
  if (isFirefox()) {
    gslbBaseURL = 'https://service.force.com';
  }
  embedded_svc = await overrideChabotConfiguration(embedded_svc, data);
  //Method to check that if user is already logged on VistaPrint site
  authenticateUser(embedded_svc, gslbBaseURL);

  const caChatConfig = getCAConfig();
  const isLightningEnabled = caChatConfig.loadLightningScript;
  if (isLightningEnabled) {
    loadLightninghScript();
  }
};

function loadLightninghScript() {
  // Adding preloading here-----
  const caChatConfig = getCAConfig();
  const url = caChatConfig.baseCoreURL;
  let _script = document.createElement('script');
  _script.type = 'text/javascript';
  _script.src = url + '/lightning/lightning.out.js';
  _script.onload = function () {
    eventBus.on('HPOpenChat', (data) => initiateCAChat(data));
    eventBus.dispatch('CALoaded');
  };
  document.getElementsByTagName('head')[0].appendChild(_script);
}
