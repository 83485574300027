import { IConfigData, ILocaleConfigType } from '../../../types/app-interfaces';

export const vcsProdConfig: ILocaleConfigType = {
  'en-us': {
    eswConfigDevName: 'ChatAnywhere_VistaNext_EN_US_Embedded_Chat',
    defaultButtonId: '573Do000000HCDt',
    ppagButtonId: '',
    checkoutButtonId: '',
    digitalButtonId: '',
    designButtonId: '',
    mspButtonId: '',
    checkBusinessHour: true,
  },
};

export const vcsCommonConfig = {
  baseLiveAgentContentURL: 'https://c.la1-c2-ia6.salesforceliveagent.com/content',
  eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IDo000000Ch2gMAC_19058bac0ef',
  deploymentId: '572Do0000011KQh',
  baseLiveAgentURL: 'https://d.la1-c2-ia6.salesforceliveagent.com/chat',
  isOfflineSupportEnabled: false,
  baseCoreURL: 'https://vistaprint.my.salesforce.com',
  communityEndpointURL: 'https://vistaprint.my.salesforce-sites.com/public',
  orgID: '00Di0000000HPcF',
  loadLightningScript: true,
  enableDraggable: false,
  addDefaultPageEvent: true,
  gaTrackingEnabled: true,
  segmentTrackingEnabled: false,
  ctaPreLoadEnabled: true,
  enableForMobile: true,
  timeToShowAutomatedInviteInSeconds: 5,
  timeForChatDuplicationCookieExpiry: 10,
};

export const getVistaNextProdConfig = (locale: string): IConfigData => {
  const localeConfig = vcsProdConfig[locale];
  const isStudio = window.location.href.includes('/studio');
  if (isStudio) {
    localeConfig.defaultButtonId = '573Do000000HCDy';
    localeConfig.eswConfigDevName = 'EmbeddedServiceLiveAgent_Parent04IDo000000Ch3PMAS_191be13cf64';
  }
  return {
    env: 'production',
    tenant: 'vcs',
    locale,
    ...localeConfig,
    ...vcsCommonConfig,
  };
};
