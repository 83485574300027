import { ILocaleConfigType } from '../../../types/app-interfaces';

export const prodConfig: ILocaleConfigType = {
  'da-dk': {
    eswConfigDevName: 'ChatAnywhere_DK_DK_Embedded_Chat',
    defaultButtonId: '57307000000oOMP',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oORK',
    digitalButtonId: '',
    designButtonId: '57307000000oOMZ',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'de-at': {
    eswConfigDevName: 'ChatAnywhere_DE_AT_Embedded_Chat',
    defaultButtonId: '57307000000oOLg',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOLl',
    digitalButtonId: '',
    designButtonId: '57307000000oOLq',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'de-ch': {
    eswConfigDevName: 'ChatAnywhere_DE_CH_Embedded_Chat',
    defaultButtonId: '57307000000oOLv',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOM5',
    digitalButtonId: '',
    designButtonId: '57307000000oOM0',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'de-de': {
    eswConfigDevName: 'ChatAnywhere_DE_DE_Embedded_Chat',
    defaultButtonId: '57307000000oOMA',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOMF',
    digitalButtonId: '',
    designButtonId: '57307000000oOMK',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'en-au': {
    eswConfigDevName: 'ChatAnywhere_EN_AU_Embedded_Chat',
    defaultButtonId: '57307000000oOMe',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOMj',
    digitalButtonId: '57307000000Xef2',
    designButtonId: '57307000000oOMo',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'en-ca': {
    eswConfigDevName: 'ChatAnywhere_EN_CA_Embedded_Chat',
    defaultButtonId: '57307000000oOMt',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOMy',
    digitalButtonId: '57307000000XeFw',
    designButtonId: '57307000000oON3',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'en-gb': {
    eswConfigDevName: 'ChatAnywhere_EN_UK_Embedded_Chat',
    defaultButtonId: '57307000000oONr',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oONw',
    digitalButtonId: '',
    designButtonId: '57307000000oOO1',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'en-ie': {
    eswConfigDevName: 'ChatAnywhere_EN_IE_Embedded_Chat',
    defaultButtonId: '57307000000oON8',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOND',
    digitalButtonId: '',
    designButtonId: '57307000000oONI',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'en-nz': {
    eswConfigDevName: 'ChatAnywhere_EN_NZ_Embedded_Chat',
    defaultButtonId: '57307000000oONN',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oONS',
    digitalButtonId: '57307000000Xef7',
    designButtonId: '57307000000oONX',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'en-sg': {
    eswConfigDevName: 'ChatAnywhere_EN_SG_Embedded_Chat',
    defaultButtonId: '57307000000oONc',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oONh',
    digitalButtonId: '',
    designButtonId: '57307000000oONm',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'en-us': {
    eswConfigDevName: 'ChatAnywhere_Embedded_Chat',
    defaultButtonId: '57307000000L1Go',
    ppagButtonId: '57307000000L1Gt',
    checkoutButtonId: '57307000000CdOK',
    digitalButtonId: '57307000000L1Gy',
    designButtonId: '57307000000L1H3',
    mspButtonId: '57307000000L1Hw',
    checkBusinessHour: true,
  },
  'es-es': {
    eswConfigDevName: 'ChatAnywhere_ES_ES_Embedded_Chat',
    defaultButtonId: '57307000000oOOB',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOOG',
    digitalButtonId: '',
    designButtonId: '57307000000oOOL',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'es-us': {
    eswConfigDevName: 'ChatAnywhere_ES_US_Embedded_Chat',
    defaultButtonId: '57307000000oOOQ',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOOV',
    digitalButtonId: '',
    designButtonId: '57307000000oOOf',
    mspButtonId: '57307000000oOOa',
    checkBusinessHour: true,
  },
  'fi-fi': {
    eswConfigDevName: 'ChatAnywhere_FI_FI_Embedded_Chat',
    defaultButtonId: '57307000000oOOk',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOOp',
    digitalButtonId: '',
    designButtonId: '57307000000oOOu',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'fr-be': {
    eswConfigDevName: 'ChatAnywhere_FR_BE_Embedded_Chat',
    defaultButtonId: '57307000000oOOz',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOP4',
    digitalButtonId: '',
    designButtonId: '57307000000oOP9',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'fr-ca': {
    eswConfigDevName: 'ChatAnywhere_FR_CA_Embedded_Chat',
    defaultButtonId: '57307000000oOPE',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOPJ',
    digitalButtonId: '',
    designButtonId: '57307000000oOPO',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'fr-ch': {
    eswConfigDevName: 'ChatAnywhere_FR_CH_Embedded_Chat',
    defaultButtonId: '57307000000oOPT',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOPY',
    digitalButtonId: '',
    designButtonId: '57307000000oOPd',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'fr-fr': {
    eswConfigDevName: 'ChatAnywhere_FR_FR_Embedded_Chat',
    defaultButtonId: '57307000000oOPi',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOPn',
    digitalButtonId: '',
    designButtonId: '57307000000oOPs',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'it-ch': {
    eswConfigDevName: 'ChatAnywhere_IT_CH_Embedded_Chat',
    defaultButtonId: '57307000000oOPx',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOQ2',
    digitalButtonId: '',
    designButtonId: '57307000000oOOb',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'it-it': {
    eswConfigDevName: 'ChatAnywhere_IT_IT_Embedded_Chat',
    defaultButtonId: '57307000000oOQH',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOQC',
    digitalButtonId: '',
    designButtonId: '57307000000oOQ7',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'nb-no': {
    eswConfigDevName: 'ChatAnywhere_NO_NO_Embedded_Chat',
    defaultButtonId: '57307000000oOQq',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOQv',
    digitalButtonId: '',
    designButtonId: '57307000000oOR0',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'nl-be': {
    eswConfigDevName: 'ChatAnywhere_NL_BE_Embedded_Chat',
    defaultButtonId: '57307000000oOQM',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOQR',
    digitalButtonId: '',
    designButtonId: '57307000000oOQW',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'nl-nl': {
    eswConfigDevName: 'ChatAnywhere_NL_NL_Embedded_Chat',
    defaultButtonId: '57307000000oOQb',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oOQg',
    digitalButtonId: '',
    designButtonId: '57307000000oOQl',
    mspButtonId: '',
    checkBusinessHour: true,
  },
  'sv-se': {
    eswConfigDevName: 'ChatAnywhere_SE_SE_Embedded_Chat',
    defaultButtonId: '57307000000oOR5',
    ppagButtonId: '',
    checkoutButtonId: '57307000000oORA',
    digitalButtonId: '',
    designButtonId: '57307000000oORF',
    mspButtonId: '',
    checkBusinessHour: true,
  },
};
