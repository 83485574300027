import AnalyticsTracking from './eventTracking';
import ContextBuilder from '../CACTA/JSComponents/JS/Context';
import { getNRId } from '../utils/newRelic';
import { getTrans } from '../CACTA/JSComponents/JS/translations';
import { enhancedPreChatPages } from '../CACTA/JSComponents/JS/helper';
import languageMapping from '../utils/languageMapping';
import { getCAConfig } from '../utils/ca-env';

const overrideChabotConfiguration = async (embedded_svc, properties) => {
  const caChatConfig = getCAConfig();
  const { defaultButtonId, digitalButtonId, designButtonId, tenant } = caChatConfig;

  const pageUrl = window.location.href;

  embedded_svc.settings.displayHelpButton = false;
  embedded_svc.settings.appendHelpButton = false;
  embedded_svc.settings.language = languageMapping(caChatConfig.locale);
  embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  embedded_svc.settings.entryFeature = 'LiveAgent';
  // if (env.includes('sandbox')) embedded_svc.settings.autoOpenPostChat = true;
  /** For Local testing */
  if (pageUrl.includes('localhost') || pageUrl.includes('staging')) {
    console.log('Test Env , Storage Domain not set');
  } else if (tenant === 'vcs') {
    embedded_svc.settings.storageDomain = window.location.href.includes('vcs-customers.eu.auth0.com')
      ? 'vcs-customers.eu.auth0.com'
      : window.location.href.includes('studio.dev.vcs.vpsvc.com')
      ? 'studio.dev.vcs.vpsvc.com'
      : window.location.href.includes('proshopnext.com')
      ? 'proshopnext.com'
      : 'ourproshop.com';
  } else if (pageUrl.includes('cheetah-product-page')) {
    embedded_svc.settings.storageDomain = 'cheetah-product-page-gatsby-git-vir-4799-ca-d0590f-huxley-squad.vercel.app';
  } else {
    embedded_svc.settings.storageDomain = getTrans('storageDomain');
  }
  embedded_svc.settings.extraPrechatInfo = [
    {
      entityName: 'Contact',
      showOnCreate: true,
      linkToEntityName: 'Case',
      linkToEntityField: 'ContactId',
      saveToTranscript: 'Contact',
      entityFieldMaps: [
        {
          isExactMatch: false,
          fieldName: 'FirstName',
          doCreate: true,
          doFind: false,
          label: getTrans('firstNameLabel'),
        },
        { isExactMatch: false, fieldName: 'LastName', doCreate: true, doFind: false, label: getTrans('lastNameLabel') },
        { isExactMatch: true, fieldName: 'Email', doCreate: true, doFind: true, label: getTrans('emailLabel') },
        { isExactMatch: true, fieldName: 'Brand__c', doCreate: true, doFind: true, label: 'Brand' },
      ],
    },
    {
      entityName: 'LiveChatTranscript',
      showOnCreate: true,
      saveToTranscript: 'LiveChatTranscript',
      entityFieldMaps: [
        {
          isExactMatch: false,
          fieldName: 'GA_Anonymous_id__c',
          doCreate: true,
          doFind: true,
          label: 'GA Anonymous Id',
        },
        {
          isExactMatch: false,
          fieldName: 'Is_Logged_In__c',
          doCreate: true,
          doFind: true,
          label: 'Is Logged In',
        },
        {
          isExactMatch: false,
          fieldName: 'New_Relic_Log_Key__c',
          doCreate: true,
          doFind: true,
          label: 'New Relic Log Key',
        },
      ],
    },
    {
      entityName: 'Case',
      showOnCreate: true,
      saveToTranscript: 'Case',
      entityFieldMaps: [
        tenant === 'vcs'
          ? {
              isExactMatch: false,
              fieldName: 'Categories__c',
              doCreate: true,
              doFind: false,
              label: 'Categories',
            }
          : {
              isExactMatch: false,
              fieldName: 'Category__c',
              doCreate: true,
              doFind: false,
              label: 'Category',
            },
        {
          isExactMatch: false,
          fieldName: 'Sub_Category__c',
          doCreate: true,
          doFind: false,
          label: 'Sub Category',
        },
        {
          isExactMatch: false,
          fieldName: 'Priority',
          doCreate: true,
          doFind: false,
          label: 'Priority',
        },
        {
          isExactMatch: false,
          fieldName: 'Description',
          doCreate: true,
          doFind: false,
          label: 'Description',
        },
        {
          isExactMatch: false,
          fieldName: 'Subject',
          doCreate: true,
          doFind: false,
          label: 'issue',
        },
        {
          isExactMatch: false,
          fieldName: 'Status',
          doCreate: true,
          doFind: false,
          label: 'Status',
        },
        {
          isExactMatch: false,
          fieldName: 'Origin',
          doCreate: true,
          doFind: false,
          label: 'Origin',
        },
        {
          isExactMatch: false,
          fieldName: 'Banner__c',
          doCreate: true,
          doFind: false,
          label: 'Banner',
        },
        {
          isExactMatch: false,
          fieldName: 'Language__c',
          doCreate: true,
          doFind: false,
          label: 'Chat Locale',
        },
      ],
    },
  ];

  if (tenant === 'vcs') {
    embedded_svc.settings.extraPrechatInfo[2].entityFieldMaps.push({
      isExactMatch: false,
      fieldName: 'Issue_Category__c',
      doCreate: true,
      doFind: false,
      label: 'Issue Category',
    });
  }
  /* We are storing firstName,lastName,Email of prechat form in LiveChatTranscript Object by the help of this we can fetch/display
      firstName in hi{firstName} in Bot Welcome Message.
       */

  let preChatFormDetailsArray = [];
  Object.keys(properties).forEach((value, index) => {
    preChatFormDetailsArray.push(properties[value]);
  });

  preChatFormDetailsArray.push(
    {
      label: getTrans('firstNameLabel'),
      transcriptFields: ['Customer_FirstName__c'],
      displayToAgent: false,
    },
    {
      label: getTrans('lastNameLabel'),
      transcriptFields: ['Customer_LastName__c'],
      displayToAgent: false,
    },
    {
      label: getTrans('emailLabel'),
      transcriptFields: ['Customer_Email__c'],
      displayToAgent: false,
    },
    {
      label: 'Phone',
      transcriptFields: ['Customer_Phone__c'],
      displayToAgent: false,
    },
    {
      label: 'GA Anonymous Id',
      transcriptFields: ['GA_Anonymous_id__c'],
      displayToAgent: false,
      value: JSON.parse(window.localStorage.getItem('ajs_anonymous_id')) || null,
    },
    {
      label: 'Is Logged In',
      transcriptFields: ['Is_Logged_In__c'],
      displayToAgent: false,
      value: false,
    },
    {
      label: 'New Relic Log Key',
      transcriptFields: ['New_Relic_Log_Key__c'],
      displayToAgent: false,
      value: getNRId(),
    },
  );

  window['embedded_svc'].settings.extraPrechatFormDetails = preChatFormDetailsArray;

  embedded_svc.settings.loadingText = 'Loading...';
  /** Custom CSS injection  */
  // embedded_svc.settings.externalStyles = ["chatbotCustomStyles"];

  /** VA text changes */
  embedded_svc.settings.defaultMinimizedText = 'Chat'; //(Defaults to Chat with an Expert)
  embedded_svc.settings.disabledMinimizedText = 'Offline'; //(Defaults to Agent Offline)

  const ctaConfig = await ContextBuilder();
  embedded_svc.settings.chatbotAvatarImgURL = JSON.stringify({ ctaConfig });

  const isEnhancedPreChatPage = enhancedPreChatPages();
  if (isEnhancedPreChatPage) {
    embedded_svc.settings.prechatBackgroundImgURL = isEnhancedPreChatPage;
    embedded_svc.settings.directToButtonRouting = function (prechatFormData) {
      let selectedSubject = prechatFormData.find((ele) => ele.label == 'Subject')?.value;
      if (selectedSubject) {
        if (selectedSubject === 'Chat - Help Center - A website') {
          return digitalButtonId;
        } else if (selectedSubject === 'Chat - Help Center - Designing a product') {
          return designButtonId;
        } else {
          return defaultButtonId;
        }
      }
    };
  }
  const isRedirectedFromProjectPage = sessionStorage.getItem('isRedirectedFromProjectPage');
  if (isRedirectedFromProjectPage && isRedirectedFromProjectPage.length) {
    embedded_svc.settings.prechatBackgroundImgURL = embedded_svc.settings.prechatBackgroundImgURL
      ? embedded_svc.settings.prechatBackgroundImgURL + '_caChatMyProjectVariant'
      : 'caChatMyProjectVariant';
  }

  const buttonId = ctaConfig.additionalProperties?.buttonId || caChatConfig.defaultButtonId;
  /** Setting up Analytics Event Tracker */
  AnalyticsTracking.handleEventTracking(embedded_svc, properties, buttonId);
  /** Adding Event listner for Chasitor message event from chatMessage LWC */
  window.addEventListener(
    'ChasitorMessage',
    (e) => {
      AnalyticsTracking.trackChatEvent('Segment', 'Chat Initiated', e.detail);
    },
    false,
  );

  return embedded_svc;
};

const updateExtraPrechatFormDetails = (value) => {
  let arr = embedded_svc.settings.extraPrechatFormDetails;
  let valueToUpdate = arr.find((val) => val.label == 'Is Logged In');
  valueToUpdate.value = value;
  arr.splice(
    arr.findIndex((obj) => obj.label == 'Is Logged In'),
    1,
    valueToUpdate,
  );
};

export { overrideChabotConfiguration, updateExtraPrechatFormDetails };
