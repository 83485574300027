import { getTrans } from '../CACTA/JSComponents/JS/translations';
import { setUserLoggedIn } from './eventTracking';
import { initializeChat } from './initChat';
import { updateExtraPrechatFormDetails } from './settingOverride';
const accountId = 'ozoDdrmewShEcbUDWX8J3V';

//Code to Check User Login Or Not
var authenticateUser = async function (embedded_svc, gslbBaseURL, data) {
  try {
    let token = '';
    if (
      window.vp &&
      window.vp.auth &&
      window.vp.auth.cache &&
      window.vp.auth.cache.accessToken &&
      window.vp.auth.cache.isSignedIn
    ) {
      token = window.vp.auth.cache.accessToken;
      const url = `https://profile.cimpress.io/v1/${accountId}/profile/me`;
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      // check response ok key is true or false
      if (response.ok) {
        const user = await response.json();
        if (user) {
          embedded_svc.settings.prepopulatedPrechatFields = {
            FirstName: user.firstName,
            LastName: user.lastName,
            Email: user.email,
            Prechat_Context__c: token,
          };
          const userTitleHeader = document.getElementById('snapins_bodytext');
          if (userTitleHeader) {
            userTitleHeader.innerHTML = getTrans('invite-header-login') + user.firstName + '!';
          }

          updateExtraPrechatFormDetails(true);
          setUserLoggedIn();
          initializeChat(embedded_svc, gslbBaseURL, data);
        } else {
          initializeChat(embedded_svc, gslbBaseURL, data);
        }
      } else {
        initializeChat(embedded_svc, gslbBaseURL, data);
      }
    } else {
      initializeChat(embedded_svc, gslbBaseURL, data);
    }
  } catch (error) {
    console.log('Error in User Authentication', { errorMessage: error.message, errorStack: error.stack });
  }
};

export { authenticateUser };
