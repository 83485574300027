import { getTrans } from '../CACTA/JSComponents/JS/translations';

// const loadingButton = document.getElementById('ca_liveagent_button_loading') as HTMLButtonElement;
// const startButton = document.getElementById('liveagent_button_online') as HTMLButtonElement;
// const chattingButton = document.getElementById('ca_liveagent_button_loading') as HTMLButtonElement;
const agentOfflineButton = document.getElementById('liveagent_button_offline') as HTMLButtonElement;
type HelpCenterButtons = 'loadingButton' | 'startButton' | 'chattingButton';
let observerOffline;
const ChangeHelpCenterButton = (
  buttonName: 'loadingButton' | 'startButton' | 'chattingButton',
  display: 'block' | 'none',
  innerText?: string,
) => {
  const loadingButton = document.getElementById('ca_liveagent_button_loading') as HTMLButtonElement;
  const startButton = document.getElementById('liveagent_button_online') as HTMLButtonElement;
  const chattingButton = document.getElementById('ca_liveagent_button_loading') as HTMLButtonElement;
  let currentButton;
  if (buttonName === 'loadingButton') currentButton = loadingButton;
  else if (buttonName === 'startButton') currentButton = startButton;
  else if (buttonName === 'chattingButton') currentButton = chattingButton;

  if (currentButton) {
    if (innerText) currentButton.innerText = innerText;
    currentButton.style.display = display;
  }
};

const updateHelpCenterButtonState = (hideButtons: HelpCenterButtons[], show: HelpCenterButtons, innertext?: string) => {
  hideButtons.forEach((btn) => ChangeHelpCenterButton(btn, 'none'));
  ChangeHelpCenterButton(show, 'block', innertext);
};

const isHelpCenterHomepage = () => {
  const path = window.location.pathname;
  const url = window.location.href;
  if (path === getTrans('helpCenterHomepage')) return true;
  if (url.includes('help-center-page.care-staging.vpsvc.com')) return true;
  return false;
};

const hideChatButtonsWhenOffline = () => {
  const loadingButton = document.getElementById('ca_liveagent_button_loading') as HTMLButtonElement;
  const startButton = document.getElementById('liveagent_button_online') as HTMLButtonElement;
  if (startButton) startButton.style.display = 'none';
  if (loadingButton) loadingButton.style.display = 'none';
};
export const registerOflineHandler = () => {
  if (isHelpCenterHomepage()) {
    if (agentOfflineButton && agentOfflineButton.style.display !== 'none') hideChatButtonsWhenOffline();
    observerOffline = new MutationObserver((mutations) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mutations.forEach((mutationRecord: any) => {
        if (
          mutationRecord &&
          (mutationRecord?.target?.id === 'liveagent_button_offline' ||
            mutationRecord?.target?.id === 'forcedOfflineButton') &&
          mutationRecord?.style?.display !== 'none'
        ) {
          hideChatButtonsWhenOffline();
        }
      });
    });
    const targetOffline = window.document.getElementById('liveagent_button_offline') as HTMLButtonElement;
    const targetForcedOffline = window.document.getElementById('forcedOfflineButton') as HTMLButtonElement;
    if (targetOffline) observerOffline?.observe(targetOffline, { attributes: true, attributeFilter: ['style'] });
    if (targetForcedOffline) {
      observerOffline?.observe(targetForcedOffline, { attributes: true, attributeFilter: ['style'] });
    }
  }
};
export { isHelpCenterHomepage, hideChatButtonsWhenOffline };
export default updateHelpCenterButtonState;
