import { eventBus } from '../../../utils/eventBus';
import { getTrans } from './translations';
import { WindowType } from '../../../types/windowType';
import { logNR } from '../../../utils/newRelic';
import { getButtonName } from '../../../utils/chatButtonMapping';
import {
  isHelpPanelAnywherePage,
  isChatEnabledFromChannelProvider,
  sendEventToHelpAnywherePanel,
  isCASession,
  sendUserDetailEventToHelpPanel,
} from './helper';
import { showChatChipOnInit } from '../HTML/CAButton';
import { conflictingPageMapping } from '../pageMapping';
import { isHelpCenterHomepage } from '../../../Services/helpCenterService';
import { isChatEnabled } from '../../../Services/channelProviderApi';

import {
  isPPAGLearnPage,
  isSearchOrDesignPage,
  isStudioButtonEnabled,
  updateStudioButton,
} from '../../../Services/legacyChatReplacementService';
import { isEnabledForMobile } from '../../../utils/mobile-utils';
import ContextBuilder from './Context';
import { getCAConfig } from '../../../utils/ca-env';

const MSG = 'CAButton';

declare const window: WindowType;
let isStudioErrorPage = false;

export const addConflictingPageCSSClass = (buttonElement, conflictingPageName, cssClass) => {
  if (window.location.href.includes(getTrans(conflictingPageName)) && window.embedded_svc.isDragStarted == false) {
    if (window.location.pathname.includes(getTrans('helpCenter'))) {
      if (isHelpCenterHomepage()) {
        (buttonElement as HTMLButtonElement).classList.add(cssClass);
      }
    } else if (conflictingPageName === 'studio') {
      if (isEnabledForMobile() && isStudioPage()) (buttonElement as HTMLButtonElement).classList.add(cssClass);
    } else (buttonElement as HTMLButtonElement).classList.add(cssClass);
  }
};

export const afterMaximize = (data) => {
  console.log(MSG, 'CAafterMaximize ', data);
  if (isHelpPanelAnywherePage()) {
    sessionStorage.removeItem('CAUnreadMessage');
    sendEventToHelpAnywherePanel('hideHelpPanel');
  } else if (isStudioPage()) {
    eventBus.dispatch('setStudioButtonLoading', 'false');
    updateStudioButton(false);
  }
  const customButton = document.getElementById('caHelpButton');
  (customButton as HTMLButtonElement).style.display = 'none';
  const minimizedButton = document.getElementsByClassName('cMinimizedChatCA')[0];
  if (minimizedButton) {
    (minimizedButton as HTMLButtonElement).style.display = 'none';
    conflictingPageMapping.map((ele) => {
      addConflictingPageCSSClass(minimizedButton, ele.pageName, ele.className);
    });
  }
};

const studioErrorPageHandle = (data) => {
  console.log('Studio Error', data);
  isStudioErrorPage = true;
};

export const isProjectsPage = () => {
  const path = window.location.pathname;
  if (path.includes(getTrans('my-account-projects'))) return true;
  return false;
};

export const isStudioPage = () => {
  const path = window.location.pathname;
  if (path.includes(getTrans('studio')) && !isStudioErrorPage) {
    const { locale } = getCAConfig();

    const allowedLocales = [
      'en-us',
      'en-gb',
      'en-ie',
      'en-ca',
      'en-au',
      'en-nz',
      'en-sg',
      'da-dk',
      'de-at',
      'de-ch',
      'de-de',
      'es-es',
      'es-us',
      'fi-fi',
      'fr-be',
      'fr-ca',
      'fr-ch',
      'fr-fr',
      'it-ch',
      'it-it',
      'nb-no',
      'sv-se',
      'nl-nl',
      'nl-be',
    ];
    if (locale && allowedLocales.includes(locale)) return true;
    return false;
  }
  return false;
};

export const afterMinimize = (data) => {
  console.log(MSG, 'CAafterMinimize', data);
  const customButton = document.getElementById('caHelpButton');
  (customButton as HTMLButtonElement).style.display = 'none';
  const minimizedButton = document.getElementsByClassName('cMinimizedChatCA')[0];
  const customChatHeaderData = sessionStorage.getItem('LSSIndex:SESSION{"namespace":"c"}');
  if (isHelpPanelAnywherePage()) {
    if (customChatHeaderData) {
      const parsedData = JSON.parse(customChatHeaderData);
      const isButtonPressed = parsedData.buttonPressed;
      if (
        isButtonPressed &&
        (typeof isButtonPressed === 'number' ||
          typeof isButtonPressed === 'string' ||
          typeof isButtonPressed === 'boolean')
      ) {
        (minimizedButton as HTMLButtonElement).style.display = 'none';
        setTimeout(() => {
          const messageCount = document.getElementsByClassName('caMessageContent')?.[0]?.innerHTML;
          if (messageCount && messageCount.toLowerCase().includes('message')) {
            console.log('message count after minimize the chat chip', messageCount[0]);
            sendEventToHelpAnywherePanel('unreadMessageCount', messageCount[0]);
            sessionStorage.setItem('CAUnreadMessage', messageCount[0]);
          }
        }, 3000);
        // updateStudioButton(true);
        sendEventToHelpAnywherePanel('showHelpPanel');
        // } else {
        //   if (!isStudioButtonEnabled()) {
        //     (minimizedButton as HTMLButtonElement).style.display = 'flex';
        //     updateStudioButton(false);
        //   } else {
        //     updateStudioButton(true);
        //   }
        // }
      } else {
        (minimizedButton as HTMLButtonElement).style.display = 'flex';
      }
    } else {
      (minimizedButton as HTMLButtonElement).style.display = 'flex';
    }
  } else if (isStudioPage()) {
    if (!isStudioButtonEnabled()) {
      (minimizedButton as HTMLButtonElement).style.display = 'flex';
      updateStudioButton(false);
    } else {
      updateStudioButton(true);
    }
  } else (minimizedButton as HTMLButtonElement).style.display = 'flex';

  // Mobile screens
  if (isEnabledForMobile()) {
    (minimizedButton as HTMLButtonElement)?.classList.add('caHelpButtonMobileEnabled');
    (minimizedButton as HTMLButtonElement)?.classList.add('swan');
  }
  // For Conflicting pages
  conflictingPageMapping.map((ele) => {
    addConflictingPageCSSClass(minimizedButton, ele.pageName, ele.className);
  });
};

const onChatEndedByAgent = (data) => {
  console.log(MSG, 'CAonChatEndedByAgent', data);
};
const onChatEndedByChasitor = (data) => {
  console.log(MSG, 'CAonChatEndedByChasitor', data);
};
export const afterDestroy = async (data) => {
  console.log(MSG, 'CAafterDestroy', data);
  const customButton = document.getElementById('caHelpButton');
  if (!showChatChipOnInit()) {
    return;
  }
  if (customButton) {
    // (customButton as HTMLButtonElement).disabled = false;
    (customButton as HTMLButtonElement).style.display = 'flex';
    const element = document.getElementById('caButtonText');
    (element as HTMLElement).innerText = getTrans('ca-message');
  }
};
const onIdleTimeoutOccurred = (data) => {
  console.log(MSG, 'CAonIdleTimeoutOccurred', data);
};

export const enableCAButton = async (data) => {
  const { checkBusinessHour } = getCAConfig();
  console.log('isChatEnabledFromChannelProvider', isChatEnabledFromChannelProvider);
  if (
    isChatEnabledFromChannelProvider ||
    !checkBusinessHour //for Sandbox
  ) {
    console.log(MSG, 'Enabling CA button', data, showChatChipOnInit(), isHelpCenterHomepage(), isSearchOrDesignPage());
    const customButton = document.getElementById('caHelpButton');
    // const hideCTAButton = hideCTAForConflictingPage();
    const showChatChipInitailly = showChatChipOnInit();
    if (!showChatChipInitailly) {
      console.log('CA CTA Not available on this page');
      return;
    }

    /** Fire Impression only when agent is available
     * Only for pages where CTA is shown
     */
    if (isHelpCenterHomepage() || isSearchOrDesignPage().isSearchOrDesignPage || isStudioPage() || isPPAGLearnPage()) {
      // Need to remove once CA preloader is enabled
    } else {
      // (customButton as HTMLButtonElement).disabled = false;
      if (customButton) {
        if ((customButton as HTMLButtonElement).classList.contains('hideCTAbutton')) {
          (customButton as HTMLButtonElement).classList.remove('hideCTAbutton');
        }
      }
      (customButton as HTMLButtonElement).style.display = 'flex';
    }

    if (!data.isAgentAvailable) {
      console.log('Agent Unavailable from SF event');
      logNR('CA_AGENT_UNAVAILABLE', { buttonName: getButtonName(data.buttonId) });
    }
  }
};

export const AgentUnavailability = async (data) => {
  console.log('GCP Response chatEnabled:', isChatEnabledFromChannelProvider, { data });
};

const initCAfromExternalSource = (data) => {
  if (isStudioPage()) {
    eventBus.dispatch('setStudioButtonLoading', 'true');
    if (data.additionalInfo === 'reviewPageNewChat') {
      window.embedded_svc.settings.prechatBackgroundImgURL = 'studioReviewPage';
    }
    //populating live design url for studio
    const liveDesignExtraDetails = window?.embedded_svc?.settings?.extraPrechatFormDetails?.filter(
      (detail) => detail.label === 'Live design URL',
    );
    if (liveDesignExtraDetails && liveDesignExtraDetails.length === 0) {
      window.addEventListener('getLiveDesignURLLink', (e) => {
        if (e.detail) {
          console.log('live design link', e.detail);
          window.embedded_svc.settings.extraPrechatFormDetails.push({
            label: 'Live design URL',
            value: 'https://' + e.detail,
            transcriptFields: ['Live_design_URL__c'],
            displayToAgent: true,
          });
        }
      });
      window.dispatchEvent(new CustomEvent('generateLiveDesignLinkEvent'));
    }
  }
  // Override the data attribute here
  const minimizedButton = document.querySelector('.cMinimizedChatCA') as HTMLElement;
  if (data.button === 'studio-chat-now' && minimizedButton) minimizedButton.click();
  eventBus.dispatch('onCAButtonClicked', data);
};

const initiateCAForHelpPanel = (data) => {
  if (
    data?.details == true &&
    getCAConfig().checkBusinessHour &&
    isHelpPanelAnywherePage() &&
    (data?.sessionContinued || !isCASession())
  ) {
    isChatEnabled().then((channelProviderData) => {
      sendEventToHelpAnywherePanel('initiate', {
        channelProviderData: JSON.stringify(channelProviderData),
        sessionContinued: data.sessionContinued,
      });
    });
  }

  if (window?.embedded_svc && window?.embedded_svc.settings) {
    let userDetail = window?.embedded_svc?.settings?.prepopulatedPrechatFields;
    if (userDetail) {
      sendUserDetailEventToHelpPanel(true, userDetail?.FirstName, userDetail?.LastName, userDetail?.Email);
    } else {
      sendUserDetailEventToHelpPanel(false);
    }
  }
};

export const initiateCAChat = async (data) => {
  const ctaConfig = await ContextBuilder();
  let sfProperties = ctaConfig['finalContext'];
  let pageNameProperty = sfProperties?.pageName?.value;

  //  if data comes or not this map code has to be run every time to reset the extraprechatformdetails;

  const indexToUpdate = window.embedded_svc.settings.extraPrechatFormDetails.findIndex(
    (obj) => obj.label === 'Description',
  );

  if (indexToUpdate !== -1) {
    window.embedded_svc.settings.extraPrechatFormDetails[indexToUpdate].value = data
      ? data
      : `${pageNameProperty} case`;
  }

  const minimizedButton = document.getElementsByClassName('cMinimizedChatCA');
  if (minimizedButton && minimizedButton.length > 0) {
    (minimizedButton[0] as HTMLButtonElement).style.display = 'flex';
    (minimizedButton[0] as HTMLButtonElement).click();
  } else {
    eventBus.dispatch('onCAButtonClicked', data);
  }
};

export const addCustomListeners = () => {
  eventBus.on('helpPanelLoaded', () => {
    eventBus.dispatch('CALoadedForHelpPanel', true);
  });

  eventBus.on('CAafterMaximize', (data) => afterMaximize(data));
  eventBus.on('CAafterMinimize', (data) => afterMinimize(data));
  eventBus.on('CAonChatEndedByAgent', (data) => onChatEndedByAgent(data));
  eventBus.on('CAonChatEndedByChasitor', (data) => onChatEndedByChasitor(data));
  eventBus.on('CAafterDestroy', (data) => afterDestroy(data));
  eventBus.on('CAonIdleTimeoutOccurred', (data) => onIdleTimeoutOccurred(data));
  eventBus.on('CAonChatInit', (data) => enableCAButton(data));
  eventBus.on('CAonAgentAvailability', (data) => AgentUnavailability(data));
  eventBus.on('initCAfromExternalSource', (data) => initCAfromExternalSource(data));
  eventBus.on('studioErrorPage', (data) => studioErrorPageHandle(data));
  eventBus.on('CALoadedForHelpPanel', (data) => initiateCAForHelpPanel(data));
};
export { isStudioErrorPage };
