import Cookies from 'js-cookie';
import { getProdConfig } from '../config/sf-config/production/getProdConfig';
import { getSandboxConfig } from '../config/sf-config/sandbox/getSandboxConfig';
import { extractLocale } from './extractLocale';
import { IConfigData } from '../types/app-interfaces';
import { getVistaNextProdConfig } from '../config/sf-config/vista-next/vistaNextProdConfig';
import { getVistaNextSandboxConfig } from '../config/sf-config/vista-next/vistaNextSandboxConfig';

const COOKIE_VALUE = 'chatAnywhere_cookie';
const COOKIE_NAME = 'ca-test';

let cachedConfig: IConfigData;

export const getCAEnv = (): 'production' | 'sandbox' => {
  if (Cookies.get(COOKIE_NAME) === COOKIE_VALUE) return 'sandbox';
  if (window.location.href.includes('localhost')) return 'sandbox';
  // For VCS
  if (window.location.href.includes('proshopnext')) return 'sandbox';
  return 'production';
};

export const initConfig = (locale?: string, tenant?: string) => {
  const updatedLocale = extractLocale(locale);
  /** Sandbox -> when cookie is set or in localhost, else production */
  const env = getCAEnv();
  if (tenant === 'vcs') {
    cachedConfig =
      env === 'production' ? getVistaNextProdConfig(updatedLocale) : getVistaNextSandboxConfig(updatedLocale);
  } else cachedConfig = env === 'production' ? getProdConfig(updatedLocale) : getSandboxConfig(updatedLocale);
};

export const getCAConfig = (locale?: string): IConfigData => {
  /** If config already set, return it */
  if (cachedConfig) {
    return cachedConfig;
  }
  const updatedLocale = extractLocale(locale);
  const env = getCAEnv();

  const config = env === 'production' ? getProdConfig(updatedLocale) : getSandboxConfig(updatedLocale);
  return config;
};
