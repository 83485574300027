import { IConfigData } from '../../../types/app-interfaces';
import { prodConfig } from './prodConfig';

export const getProdConfig = (locale: string): IConfigData => {
  const localeConfig = prodConfig[locale];
  return {
    env: 'production',
    tenant: 'vistaprint',
    locale,
    ...localeConfig,
    baseLiveAgentContentURL: 'https://c.la11-core1.sfdc-yzvdd4.salesforceliveagent.com/content',
    eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I07000000Cbg4EAC_185dd6e1140',
    deploymentId: '57207000000Gp73',
    baseLiveAgentURL: 'https://d.la11-core1.sfdc-yzvdd4.salesforceliveagent.com/chat',
    isOfflineSupportEnabled: false,
    baseCoreURL: 'https://vpcare.my.salesforce.com/',
    communityEndpointURL: 'https://vpcare.my.salesforce-sites.com/public',
    orgID: '00D410000007e46',
    loadLightningScript: true,
    enableDraggable: false,
    addDefaultPageEvent: true,
    gaTrackingEnabled: true,
    segmentTrackingEnabled: false,
    ctaPreLoadEnabled: true,
    enableForMobile: true,
    timeToShowAutomatedInviteInSeconds: 5,
    timeForChatDuplicationCookieExpiry: 10,
  };
};
