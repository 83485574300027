import { eventBus } from '../utils/eventBus';
import { logNR } from '../utils/newRelic';
import { getButtonName } from '../utils/chatButtonMapping';
import { dragElement } from './draggableChatChip';
import Cookies from 'js-cookie';
import updateHelpCenterButtonState, { isHelpCenterHomepage } from '../Services/helpCenterService';
import { addConflictingPageCSSClass, isStudioPage, isProjectsPage } from '../CACTA/JSComponents/JS/eventManagement';
import { detectBrowser } from '../CACTA/JSComponents/JS/helper';
import updateButtonState, {
  isPPAGLearnPage,
  isPPAGFreeSamples,
  isSearchOrDesignPage,
  updatePPAGInlineButton,
  updateStudioButton,
} from '../Services/legacyChatReplacementService';
import { getTrans } from '../CACTA/JSComponents/JS/translations';
import { conflictingPageMapping } from '../CACTA/JSComponents/pageMapping';

const logging = 'false';
import {
  showCAButton,
  isPageConflicting,
  playNotification,
  hideCAButton,
  isMobilePageConflicting,
  sendEventToHelpAnywherePanel,
  sendEventForSessionNotContinued,
  isHelpPanelAnywherePage,
  isCASession,
  sendAuthTokenForMyProjectDetails,
  isChatEnabledFromChannelProvider,
} from '../CACTA/JSComponents/JS/helper';
import { isEnabledForMobile, isMobileOrTablet, isMobile } from '../utils/mobile-utils';
import { getVariation } from '@vp/ab-reader';
import { getCAConfig } from '../utils/ca-env';

let segmentTrackingTrigger = true;
let chatStartedByUser = false;
let isUserLoggedIn = false;
let liveDesignTimeout;
let scriptLoadedEventFired = false;
let agentAvailabilityfromSettingCall = {};

export const setUserLoggedIn = () => (isUserLoggedIn = true);

const CA_CHAT_REQUEST_TIME_KEY = 'ca-chat-request-time';

export default class AnalyticsTracking {
  static handleEventTracking(embedded_svc, contextData, buttonId) {
    /*
     * Reference: https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_chat_events.htm
     */
    const caChatConfig = getCAConfig();
    const populateLiveDesignLink = () => {
      const liveDesignExtraDetails = window?.embedded_svc?.settings?.extraPrechatFormDetails?.filter(
        (detail) => detail.label === 'Live design URL',
      );
      if (liveDesignExtraDetails && liveDesignExtraDetails.length === 0) {
        window.addEventListener('getLiveDesignURLLink', (e) => {
          logNR('CA_DESIGN_LINK', { generatedDesignLink: e.detail });
          if (e.detail) {
            liveDesignTimeout = setTimeout(() => {
              let url = 'https://' + e.detail;
              window.embedded_svc.liveAgentAPI.sendCustomEvent('Customer_Request_Live_Design_Session_Event', url);
            }, 0);
          }
        });
        window.dispatchEvent(new CustomEvent('generateLiveDesignLinkEvent'));
      }
    };
    let isAgentAvailable = false;
    embedded_svc.addEventHandler('onSettingsCallCompleted', function (data) {
      try {
        console.log('onSettingsCallCompleted', { data });
        if (isAgentAvailable !== data.isAgentAvailable && isChatEnabledFromChannelProvider) {
          isAgentAvailable = data.isAgentAvailable ? data.isAgentAvailable : false;
          logNR('CA_AGENT_UNAVAILABILITY', {
            isChatEnabled: isChatEnabledFromChannelProvider,
            isAgentAvailable: isAgentAvailable,
            customerBrowser: detectBrowser(),
            customerBrowsertime: new Date().toLocaleTimeString(),
            isHelpCenterHomepage: isHelpCenterHomepage(),
            isStudioPage: isStudioPage(),
            pageName: contextData['pageName'].value || 'Others',
            pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
            pageStage: contextData['pageStage'].value || 'Others',
          });
        }
        agentAvailabilityfromSettingCall = data;
        // Preloading JS Files-----------
        new Promise((resolve) => {
          const isLightningEnabled = caChatConfig.loadLightningScript;
          if (isLightningEnabled) {
            resolve(
              embedded_svc.instantiateLightningOutApplication &&
                embedded_svc
                  .instantiateLightningOutApplication()
                  .then(() => console.log('instantiateLightningOutApplication success')),
            );
          } else {
            resolve(
              embedded_svc.loadLightningOutScripts &&
                embedded_svc.loadLightningOutScripts().then(() => {
                  embedded_svc.instantiateLightningOutApplication &&
                    embedded_svc.instantiateLightningOutApplication().then(() => {});
                }),
            );
          }
        }).then(() => {
          if (!scriptLoadedEventFired) {
            console.log('Dispatching ChatScriptsLoaded event ->onsettings call completed');
            document.dispatchEvent(new CustomEvent('ChatScriptsLoaded', { detail: {} }));
            scriptLoadedEventFired = true;
          }
          let enableDraggable = caChatConfig.enableDraggable;
          if (enableDraggable) {
            dragElement(document.getElementsByClassName('caHelpButtonEnabled')[0]);
          }
          if (window.$Lightning) {
            eventBus.dispatch('CAonChatInit', { ...data, buttonId });
          } else {
            let count = 0;
            let timer = setInterval(checkForLightningObject, 400);
            function checkForLightningObject() {
              count++;
              if (window.$Lightning || count == 5) {
                clearInterval(timer);
                eventBus.dispatch('CAonChatInit', { ...data, buttonId });
              }
            }
            eventBus.dispatch('CAonChatInit', { ...data, buttonId });
          }
          window.CALoaded = true;
          if (window.helpPanelLoaded && isHelpPanelAnywherePage() && !isCASession()) {
            eventBus.dispatch('CALoadedForHelpPanel', true);
          }
          if (
            isHelpCenterHomepage() ||
            (isSearchOrDesignPage().isSearchOrDesignPage && data.isAgentAvailable) ||
            isStudioPage()
          ) {
            // Click CA Button based on session info
            if (window.isChatNowClicked) {
              delete window.isChatNowClicked;
              eventBus.dispatch('onCAButtonClicked', data);
            }
          }
          // if (isHelpCenterHomepage() && !data.isAgentAvailable) {
          //   hideChatButtonsWhenOffline();
          // }
          /** in case the ca scripts are loaded before HC page, send the event after recieving event from HC */
          if (isHelpCenterHomepage()) {
            document.addEventListener('helpCenterCAEvent', () => {
              console.log('Help Center event captured', 'onSettingsCallCompleted', agentAvailabilityfromSettingCall);
              eventBus.dispatch('CAonChatInit', { ...agentAvailabilityfromSettingCall, buttonId });
            });
          }

          if (isHelpPanelAnywherePage()) {
            sendEventToHelpAnywherePanel('hideChatScriptLoader');
          }

          /** For PPAG Learn page, update button as per agent availabilty */
          if (isPPAGLearnPage() || isPPAGFreeSamples()) {
            updatePPAGInlineButton(
              'Start chat',
              data.isAgentAvailable ? false : true,
              isPPAGLearnPage(),
              isPPAGFreeSamples(),
            );
          }
          // Dispatching ChatScriptsLoaded event
          document.dispatchEvent(new CustomEvent('ChatScriptsLoaded', { detail: {} }));
        });
      } catch (error) {
        logNR('CA_EVENTS_ERROR', {
          errorMessage: error.message,
          errorStack: error.stack,
          eventName: 'onSettingsCallCompleted',
        });
      }
      // Open prechat without animation
      // embedded_svc.createEmbeddedServiceComponent()
    });

    embedded_svc.addEventHandler('sessiondataretrieved', (data) => {
      try {
        data = data || {};
        if (data.CHASITOR_SERIALIZED_KEY) {
          const CHASITOR_INFO = JSON.parse(data?.CHASITOR_SERIALIZED_KEY || {});
          if (CHASITOR_INFO && CHASITOR_INFO.connectionData?.sessionId) {
            // session for reseller page
            sessionStorage.setItem('ca-user', 'non-proadv');
            localStorage.setItem('ca-session', 'active');
            // CA CTA preload if session persist
            const isCtaPreLoadingEnabled = caChatConfig.ctaPreLoadEnabled || false;
            if (isCtaPreLoadingEnabled && !isPageConflicting()) {
              if (isPageConflicting()) {
                const minimizedButton = document.getElementById('caHelpButton');
                if (minimizedButton) {
                  conflictingPageMapping.map((ele) => {
                    addConflictingPageCSSClass(minimizedButton, ele.pageName, caChatConfig.locale, ele.className);
                  });
                }
              }
              if (!isHelpCenterHomepage()) showCAButton(true);
            }
            if (isHelpPanelAnywherePage()) {
              sendEventToHelpAnywherePanel('hideHelpPanel');
              populateLiveDesignLink();
            } else if (isStudioPage()) {
              updateStudioButton(false);
              populateLiveDesignLink();
            }
            // Session handling for help center page
            if (isHelpCenterHomepage()) {
              updateHelpCenterButtonState(
                ['loadingButton', 'startButton'],
                'chattingButton',
                getTrans('chatting-text'),
              );
              setTimeout(() => {
                updateHelpCenterButtonState(
                  ['loadingButton', 'startButton'],
                  'chattingButton',
                  getTrans('chatting-text'),
                );
              }, 1000);
              /** in case the ca scripts are loaded before HC page, send the event after recieving event from HC */
              document.addEventListener('helpCenterCAEvent', () => {
                console.log('Help Center event captured', 'sessionDataRetrived');
                eventBus.dispatch('CAonSessionActive', {});
              });
              eventBus.dispatch('CAonSessionActive', {});
            } else if (isSearchOrDesignPage().isSearchOrDesignPage) {
              updateButtonState(getTrans('chatting-text'), true);
            } else if (isCtaPreLoadingEnabled) {
              // CA CTA preload if session persist
              showCAButton();
            }
            if (isHelpPanelAnywherePage()) {
              sendEventToHelpAnywherePanel('hideChatScriptLoader');
            }

            if (isProjectsPage()) {
              //Fire online event for my project page
              window.caLoadedForMyProjects = true;
              eventBus.dispatch('showChatCtaMyProjects', { chatStatus: true });
            }
            /** if session is continued, disable the inline button */
            if (isPPAGLearnPage() || isPPAGFreeSamples()) {
              updatePPAGInlineButton('Chatting...', true, isPPAGLearnPage(), isPPAGFreeSamples());
            }

            // CA Preload end
            var currURL = window.location.href;
            const laVisitorInfo = data?.LA_VISITOR_INFO;
            if (laVisitorInfo) {
              var prevURL = JSON.parse(laVisitorInfo || {})?.pages[0]?.location;
              if (prevURL !== currURL) {
                setTimeout(() => {
                  embedded_svc.liveAgentAPI.sendCustomEvent('Page_Change_Event', currURL);
                }, 10000);
              } else {
                setTimeout(() => {
                  embedded_svc.liveAgentAPI.sendCustomEvent('Page_Refresh_Event', 'Page_Refresh_Event');
                }, 10000);
              }
            }
            if (window.helpPanelLoaded && isHelpPanelAnywherePage()) {
              eventBus.dispatch('CALoadedForHelpPanel', { details: true, sessionContinued: true });
            }
          } else {
            sessionStorage.setItem('ca-user', '');
            sendEventForSessionNotContinued();
          }
        } else {
          sessionStorage.setItem('ca-user', '');
          sendEventForSessionNotContinued();
          localStorage.removeItem('ca-session');
        }
      } catch (error) {
        console.error(error);
        logNR('CA_EVENTS_ERROR', {
          errorMessage: error.message,
          errorStack: error.stack,
          eventName: 'sessiondataretrieved',
        });
      }
    });

    window.embedded_svc.addEventHandler('onAgentMessage', (data) => {
      let chatContainer = document.getElementsByClassName('dockableContainer showDockableContainer');
      if (isHelpPanelAnywherePage() && chatContainer?.length == 0) {
        let currentCount = 0;
        const messageCount = sessionStorage.getItem('CAUnreadMessage');
        if (messageCount) {
          currentCount = messageCount;
        }
        if (currentCount && !Number.isNaN(parseInt(currentCount))) {
          sessionStorage.setItem('CAUnreadMessage', parseInt(currentCount) + 1);
          sendEventToHelpAnywherePanel('unreadMessageCount', parseInt(currentCount) + 1);
        } else {
          sendEventToHelpAnywherePanel('unreadMessageCount', 1);
          sessionStorage.setItem('CAUnreadMessage', 1);
        }
      } else {
        // sendEventToHelpAnywherePanel('unreadMessageCount', 0);
        sessionStorage.removeItem('CAUnreadMessage');
      }
      // Plays notification sound on recieving message
      !isMobileOrTablet() && playNotification();
      // scroll height is total height, offset height is height of element
      // If 2 times scroll top is greater than (total height - element height), that means we are on same page/screen so we scroll down
      // If scroll top value is less then it means we are somewhere on top side, so no scroll
      const messageArea = document.getElementsByClassName('messageArea');
      if (Object.keys(messageArea).length) {
        if (2 * messageArea[0].scrollTop >= messageArea[0].scrollHeight - messageArea[0].offsetHeight) {
          messageArea[0].scrollTop = messageArea[0].scrollHeight;
        }
      }
    });

    embedded_svc.addEventHandler('onAvailability', function (data) {
      console.log('onAvailability', data);
      eventBus.dispatch('CAonAgentAvailability', data);
      if (isAgentAvailable !== data.isAgentAvailable && isChatEnabledFromChannelProvider) {
        if (data.isAgentAvailable && isAgentAvailable === false) {
          isAgentAvailable = data.isAgentAvailable;
          logNR('CA_AGENT_UNAVAILABILITY', {
            isChatEnabled: isChatEnabledFromChannelProvider,
            isAgentAvailable: isAgentAvailable,
            customerBrowser: detectBrowser(),
            customerBrowsertime: new Date().toLocaleTimeString(),
            isHelpCenterHomepage: isHelpCenterHomepage(),
            isStudioPage: isStudioPage(),
            pageName: contextData['pageName'].value || 'Others',
            pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
            pageStage: contextData['pageStage'].value || 'Others',
          });
        }
        if (data.isAgentAvailable === undefined && isAgentAvailable === true) {
          isAgentAvailable = false;
          logNR('CA_AGENT_UNAVAILABILITY', {
            category: 'CA agent availability',
            isChatEnabled: isChatEnabledFromChannelProvider,
            isAgentAvailable: isAgentAvailable,
            customerBrowser: detectBrowser(),
            customerBrowsertime: new Date().toLocaleTimeString(),
            isHelpCenterHomepage: isHelpCenterHomepage(),
            isStudioPage: isStudioPage(),
            pageName: contextData['pageName'].value || 'Others',
            pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
            pageStage: contextData['pageStage'].value || 'Others',
          });
        }
      }
    });
    embedded_svc.addEventHandler('afterMaximize', function (data) {
      console.log('afterMaximize', data);
      //CAafterMaximize
      document.querySelector('.embeddedServiceSidebar')?.querySelector('[name="closeButton"]')?.focus();

      var chatHeaderElement = document.querySelector('.modalContainer .dockableContainer');
      chatHeaderElement
        ?.getElementsByClassName('sidebarHeader')?.[0]
        ?.getElementsByClassName('minimizeButton')?.[0]
        ?.blur();
      // Hybrid Scroll
      const messageArea = document.getElementsByClassName('messageArea');
      if (Object.keys(messageArea).length) {
        if (2 * messageArea[0].scrollTop >= messageArea[0].scrollHeight - messageArea[0].offsetHeight) {
          messageArea[0].scrollTop = messageArea[0].scrollHeight;
        }
      }
      if (isHelpPanelAnywherePage()) {
        sendEventToHelpAnywherePanel('hideChatLoader');
      }
      /** If chat is opened, disable the inline button */
      if (isPPAGLearnPage() || isPPAGFreeSamples()) {
        updatePPAGInlineButton('Chatting...', true, isPPAGLearnPage(), isPPAGFreeSamples());
      }
      setTimeout(() => {
        if (isStudioPage()) {
          const prechatForm = document.querySelector('.embeddedServiceSidebar .dockableContainer');
          if (prechatForm) {
            const swanCheckbox = prechatForm.getElementsByClassName('swan-checkbox')?.[0];
            if (swanCheckbox) swanCheckbox.classList?.replace('swan-checkbox', 'chat-anywhere-checkbox');
          }
        }
      }, 500);

      eventBus.dispatch('CAafterMaximize', data);
    });

    embedded_svc.addEventHandler('onChatEndedByAgent', function (data) {
      if (isStudioPage() && liveDesignTimeout) clearTimeout(liveDesignTimeout);
      console.log('onChatEndedByAgent', data);
      logNR('CA_CHAT_ENDED_BY_AGENT', { buttonName: getButtonName(buttonId) });
      try {
        AnalyticsTracking.trackChatEvent('Segment', 'Chat Initiated', {
          category: 'Global Chat CA',
          label: isMobileOrTablet() ? 'Chat closed by Agent Mobile' : 'Chat closed by Agent',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
          pageStage: contextData['pageStage'].value || 'Others',
        });

        localStorage.removeItem('ca-session');
      } catch (error) {
        console.log('error', error);
        logNR('CA_EVENTS_ERROR', {
          errorMessage: error.message,
          errorStack: error.stack,
          eventName: 'onChatEndedByAgent',
        });
      }
      eventBus.dispatch('CAonChatEndedByAgent', data);
    });

    embedded_svc.addEventHandler('onChatEndedByChatbot', function (data) {
      console.log('onChatEndedByChatbot', data);
      eventBus.dispatch('CAonChatEndedByChatbot', data);
    });
    embedded_svc.addEventHandler('onIdleTimeoutOccurred', function (data) {
      console.log('onIdleTimeoutOccurred', data);
      eventBus.dispatch('CAonIdleTimeoutOccurred', data);
    });

    embedded_svc.addEventHandler('afterDestroy', function (data) {
      console.log('afterDestroy', data);
      // session for reseller page
      sessionStorage.removeItem('ca-user');
      localStorage.removeItem('ca-session');

      /** Once chat is closed, Enable the inline chat button */
      if (isPPAGLearnPage()) {
        updatePPAGInlineButton('Start chat', false, isPPAGLearnPage(), isPPAGFreeSamples());
        hideCAButton();
      } else if (isPPAGFreeSamples()) {
        updatePPAGInlineButton('Start chat', false, isPPAGLearnPage(), isPPAGFreeSamples());
        eventBus.dispatch('CAafterDestroy', data);
      } else if (isHelpCenterHomepage()) {
        eventBus.dispatch('CAafterDestroy', data);
        hideCAButton();
        updateHelpCenterButtonState(['loadingButton', 'chattingButton'], 'startButton');
      } else if (isSearchOrDesignPage().isSearchOrDesignPage) {
        const buttonText =
          isSearchOrDesignPage().pageName === 'Search' ? getTrans('live-chat-label') : getTrans('ca-message');
        updateButtonState(buttonText, false);
      } else if (isHelpPanelAnywherePage()) {
        console.warn('HA page');
        hideCAButton();
        sendEventToHelpAnywherePanel('showHelpPanelButton');
      } else if (isStudioPage()) {
        hideCAButton();
        updateStudioButton(true);
      } else {
        let enableDraggable = caChatConfig.enableDraggable;
        if (enableDraggable) {
          dragElement(document.getElementsByClassName('caHelpButtonEnabled')[0]);
        }
        eventBus.dispatch('CAafterDestroy', data);
      }
    });

    /** When Chat is Ended by visitor*/

    embedded_svc.addEventHandler('onChatEndedByChasitor', function (data) {
      if (isStudioPage() && liveDesignTimeout) clearTimeout(liveDesignTimeout);
      console.log('onChatEndedByChasitor', data);
      logNR('CA_CHAT_ENDED_BY_USER', { buttonName: getButtonName(buttonId) });
      try {
        AnalyticsTracking.trackChatEvent('Segment', 'Chat Initiated', {
          category: 'Global Chat CA',
          label: isMobileOrTablet() ? 'Chat closed by User Mobile' : 'Chat closed by User',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
          pageStage: contextData['pageStage'].value || 'Others',
        });
        //event tracking that should be passsed to GA
        AnalyticsTracking.trackChatEvent('GA', 'Chat Initiated', {
          category: 'Global Chat',
          label: 'Chat closed - Chat Anywhere',
          eventDetail: (contextData['pagePath'].value || '') + ';Chat Close',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others',
          pageStage: contextData['pageStage'].value || 'Others',
        });
        localStorage.removeItem('ca-session');
      } catch (error) {
        console.log('error', error);
        logNR('CA_EVENTS_ERROR', {
          errorMessage: error.message,
          errorStack: error.stack,
          eventName: 'onChatEndedByChasitor',
        });
      }
      eventBus.dispatch('CAonChatEndedByChasitor', data);
    });

    /*
     * Reference: https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_chat_events.htm
     */

    // On help button clicked
    window.embedded_svc.addEventHandler('onHelpButtonClick', function () {
      console.log('onHelpButtonClick');
      //Handling Help-center button state
      if (isHelpCenterHomepage()) {
        updateHelpCenterButtonState(['loadingButton', 'startButton'], 'chattingButton', getTrans('chatting-text'));
      } else if (isSearchOrDesignPage().isSearchOrDesignPage) {
        updateButtonState(getTrans('chatting-text'), true);
      }
      logNR('CA_HELP_BUTTON_CLICKED', { buttonName: getButtonName(buttonId) });

      try {
        AnalyticsTracking.trackChatEvent('Segment', 'Chat Initiated', {
          category: 'Global Chat CA',
          label: isMobileOrTablet() ? 'Chat opened Mobile' : 'Chat opened',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
          pageStage: contextData['pageStage'].value || 'Others',
        });
        //event tracking that should be passsed to GA
        AnalyticsTracking.trackChatEvent('GA', 'Chat Initiated', {
          category: 'Global Chat',
          label: 'Chat help initiated - Chat Anywhere',
          eventDetail: (contextData['pagePath'].value || '') + ';Chat Initiate',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
          pageStage: contextData['pageStage'].value || 'Others',
        });
      } catch (error) {
        console.log('error', error);
        logNR('CA_EVENTS_ERROR', {
          errorMessage: error.message,
          errorStack: error.stack,
          eventName: 'onHelpButtonClick',
        });
      }
    });
    /** When Chat is Established */
    embedded_svc.addEventHandler('onChatRequestSuccess', function (data) {
      if (!isHelpPanelAnywherePage() && isStudioPage()) {
        updateStudioButton(false);
      }
      console.log('onChatRequestSuccess', data);
      // session for reseller page
      sessionStorage.setItem('ca-user', 'non-proadv');
      localStorage.setItem('ca-session', 'active');
      logNR('CA_CHAT_REQUEST_SUCCESS', { buttonName: getButtonName(buttonId) });
      // Caculating waiting time
      Cookies.set(CA_CHAT_REQUEST_TIME_KEY, new Date());
      try {
        AnalyticsTracking.trackChatEvent('Segment', 'Chat Initiated', {
          category: 'Global Chat CA',
          label: isMobileOrTablet() ? 'Chat opened Mobile' : 'Chat opened',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
          pageStage: contextData['pageStage'].value || 'Others',
        });
      } catch (error) {
        console.log('error', error);
        logNR('CA_EVENTS_ERROR', {
          errorMessage: error.message,
          errorStack: error.stack,
          eventName: 'onChatRequestSuccess',
        });
      }
    });

    // On chat established
    window.embedded_svc.addEventHandler('onChatEstablished', (data) => {
      const isRedirectedFromProjectPage = sessionStorage.getItem('isRedirectedFromProjectPage');
      if (isRedirectedFromProjectPage && typeof isRedirectedFromProjectPage == 'string') {
        let projectDetail = JSON.parse(isRedirectedFromProjectPage);
        setTimeout(() => {
          let caseDetailsToSend = {
            origin: isMobile()
              ? `VistaPrint-ChatAnywhere-Mobile-${caChatConfig.locale}-My Account: Projects-SelectedProject`
              : `VistaPrint-ChatAnywhere-${caChatConfig.locale}-My Account: Projects-SelectedProject`,
            projectId: projectDetail?.projectId,
            projectDesc: projectDetail?.projectName,
          };
          window.embedded_svc.liveAgentAPI.sendCustomEvent('myProjectExpertService', JSON.stringify(caseDetailsToSend));
          sessionStorage.removeItem('isRedirectedFromProjectPage');
        }, 10000);
      }
      if (isAutomatedInvitePageStudio()) {
        const variationKeyProActive_v2 = getVariation('proactive_chat_in_studio_and_design_review');
        if (variationKeyProActive_v2 === 'pcsd_test2' && window.isStudioReviewPage) {
          setTimeout(() => {
            window.embedded_svc.liveAgentAPI.sendCustomEvent('studioReviewPage', caChatConfig.locale);
          }, 10000);
        }
      }
      if (isHelpPanelAnywherePage()) {
        sendEventToHelpAnywherePanel('chatEstablished');
      }

      if (isProjectsPage()) {
        //Fire online event for my project page
        eventBus.dispatch('showChatCtaMyProjects', { chatStatus: true });
      }

      if (isStudioPage()) {
        populateLiveDesignLink();
      }
      sendAuthTokenForMyProjectDetails();
      // session for reseller page
      sessionStorage.setItem('ca-user', 'non-proadv');
      localStorage.setItem('ca-session', 'active');
      // Caculating waiting time
      let elapsedTime;
      const startTime = Cookies.get(CA_CHAT_REQUEST_TIME_KEY);
      if (startTime) {
        elapsedTime = (new Date().getTime() - new Date(startTime).getTime()) / 1000;
        Cookies.remove(CA_CHAT_REQUEST_TIME_KEY);
      }
      logNR('CA_CHAT_ESTABLISHED', {
        buttonName: getButtonName(buttonId),
        waitingTime: elapsedTime || 0,
        isUserLoggedIn,
      });

      try {
        AnalyticsTracking.trackChatEvent('Segment', 'Chat Initiated', {
          category: 'Global Chat CA',
          label: isMobileOrTablet() ? 'Chat established Mobile' : 'Chat established',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others', // the section of the page where the interaction happened
          pageStage: contextData['pageStage'].value || 'Others',
        });
        //event tracking that should be passsed to GA
        AnalyticsTracking.trackChatEvent('GA', 'Chat Initiated', {
          category: 'Global Chat',
          label: 'Chat opened - Chat Anywhere',
          eventDetail: (contextData['pagePath'].value || '') + ';Chat Start',
          pageName: contextData['pageName'].value || 'Others',
          pageSection: contextData['pageSection'].value || 'Others',
          pageStage: contextData['pageStage'].value || 'Others',
        });
      } catch (error) {
        console.log('error', error);
        logNR('CA_EVENTS_ERROR', {
          errorMessage: error.message,
          errorStack: error.stack,
          eventName: 'onChatEstablished',
        });
      }
    });
    embedded_svc.addEventHandler('afterInitialization', function () {
      console.log(
        'Embedded Service component created. This event is called after aura_prod loads in case of active session.',
      );
      if (localStorage.getItem('ca-session') === 'active' && !scriptLoadedEventFired) {
        console.log('Dispatching ChatScriptsLoaded event -> afterInitialization');
        document.dispatchEvent(new CustomEvent('ChatScriptsLoaded', { detail: {} }));
        scriptLoadedEventFired = true;
      }
      if (localStorage.getItem('ca-session') === 'active' && isStudioPage()) {
        if (isHelpPanelAnywherePage()) sendEventToHelpAnywherePanel('hideHelpPanel');
        else updateStudioButton(false);
      }
    });
    /* timeout implementation*/
    embedded_svc.addEventHandler('onIdleTimeoutWarningStart', function (data) {
      console.log('onIdleTimeoutWarningStart event was fired.  liveAgentSessionKey was ' + data.liveAgentSessionKey);
      embedded_svc.liveAgentAPI.sendCustomEvent('Idle_Timeout_Warning_Start', 'Idle_Timeout_Warning_Start');
    });
    embedded_svc.addEventHandler('onIdleTimeoutClear', function (data) {
      console.log('onIdleTimeoutClear event was fired.  liveAgentSessionKey was ' + data.liveAgentSessionKey);
      embedded_svc.liveAgentAPI.sendCustomEvent('Idle_Timeout_Clear', 'Idle_Timeout_Clear');
    });
    /* When Chat gets Minimized */
    embedded_svc.addEventHandler('afterMinimize', function (data) {
      eventBus.dispatch('CAafterMinimize', data);
      let enableDraggable = caChatConfig.enableDraggable;
      console.log('enableDraggable', enableDraggable);
      if (enableDraggable) {
        dragElement(document.getElementsByClassName('cMinimizedChatCA')[0]);
      }
    });
    embedded_svc.addEventHandler('onConnectionError', () => {
      logNR('CA_AGENT_CONNECTION_LOST', { buttonName: getButtonName(buttonId) });
    });

    embedded_svc.addEventHandler('onChatTransferSuccessful', function (data) {
      console.log('onChatTransferSuccessful event was fired.  liveAgentSessionKey was ' + data.liveAgentSessionKey);
      sendAuthTokenForMyProjectDetails();
    });

    window.embedded_svc.addEventHandler('onChasitorMessage', () => {
      console.log('event when user sends any message');
      try {
        //event tracking that should be passsed to GA
        if (!chatStartedByUser) {
          chatStartedByUser = true;
          AnalyticsTracking.trackChatEvent('GA', 'Chat Initiated', {
            category: 'Global Chat',
            label: 'Chat submitted - Chat Anywhere',
            eventDetail: (contextData['pagePath'].value || '') + ';Chat Submit',
            pageName: contextData['pageName'].value || 'Others',
            pageSection: contextData['pageSection'].value || 'Others',
            pageStage: contextData['pageStage'].value || 'Others',
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    });
  }

  static trackChatEvent(eventType, eventName, details) {
    const caChatConfig = getCAConfig();
    if (
      (eventType === 'GA' && caChatConfig.gaTrackingEnabled) ||
      (eventType === 'Segment' && caChatConfig.segmentTrackingEnabled)
    )
      AnalyticsTracking.sendEvent(() => {
        if (caChatConfig.env?.includes('production') || caChatConfig.env.includes('sandbox')) {
          window.tracking.track(eventName, details);
        }
        if (logging === 'true') {
          var eventMessage = 'Mock Analytics Click Event Fired: ' + JSON.stringify(details);
          console.log('info', eventMessage);
        }
      }, 0);
  }

  static sendEvent(event, breaker) {
    if (window && window.tracking && window.tracking.track) {
      event();
    } else if (breaker > 20) {
      // after 20 seconds this had better exist
      if (logging === 'true') {
        console.warn('Analytics tracking not loaded - has it been included?');
      }
    } else {
      setTimeout(() => this.sendEvent(event, ++breaker), 1000);
    }
  }
}
