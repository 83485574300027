import { isProAdvantageCustomer } from '../../../utils/pro-advantage-utils';
import { handleOfflineChat, scriptLoad } from './helper';
import { initNR, logNR } from '../../../utils/newRelic';
import { initialize, whenAvailable, isAvailable } from '@vp/ab-reader';
import { getCAConfig, initConfig } from '../../../utils/ca-env';
import { WindowType } from '../../../types/windowType';
import { addCustomListeners } from './eventManagement';
import { registerOflineHandler } from '../../../Services/helpCenterService';
import { initTranslations } from './translations';
import { isChatActive } from '../../../utils/chat-utils';
import { showChatChipOnInit } from '../HTML/CAButton';
import { isChatEnabled } from '../../../Services/channelProviderApi';

interface IInitChatAnywhereProps {
  locale: string;
  tenant?: string;
  pageName?: string;
  pageSection?: string;
  pagePage?: string;
}

declare const window: WindowType;
// const CA_UBIK_FEATURE_FLAG = 'chat_anywhere_ubik';

const ubikEnabledPages = [
  'Special Offers',
  'My Projects',
  'Checkout',
  'promotionalProducts:Category Page',
  'clothingAndBags:Category Page',
  'carepageppag:Category Page',
  'promotionalproductsfreesamplesforbusinesses:Category Page',
  'My Uploads',
];

const initChatAnywhere = (data: IInitChatAnywhereProps, scriptMode?: boolean) => {
  try {
    /** When ab library is ready  */
    if (!isAvailable) initialize();
    whenAvailable(() => {
      let isEnabled = false;
      ubikEnabledPages.forEach((page) => {
        if (data.pageName && data.pageName?.toLowerCase()?.includes(page?.toLowerCase())) isEnabled = true;
      });
      if (isEnabled || scriptMode || data.tenant === 'vcs') {
        // Use this variable to prevent multiple load of script
        if (!window.chatLoaded) {
          window.chatLoaded = true;
          initConfig(data.locale?.toLowerCase(), data.tenant);
          initTranslations().then(async () => {
            const { locale: CALocale, env: CAEnv, checkBusinessHour, tenant } = getCAConfig();
            window.CALocale = CALocale;
            window.CAEnv = CAEnv;
            console.info({ CAEnv });
            const channelProviderData = await isChatEnabled();
            const chatStatus = channelProviderData.status.isChatEnabled;

            console.log({ checkBusinessHour, chatStatus });
            // If Business hours check is disabled or
            // if business hours are checked and chat is enabled from GCP
            if (!checkBusinessHour || (checkBusinessHour && chatStatus)) {
              /** Add SF custom listeners */
              addCustomListeners();
              registerOflineHandler();
              /** Check for pro-adv user */
              const isUserProAdvantage = isProAdvantageCustomer();
              // Allowring pro Ad session changes in locales
              const allowedProAdLocales = ['en-us', 'en-ca'];
              console.log({ isUserProAdvantage });
              /** CA will be enabled only for non pro-adv users */
              if (isChatActive() || showChatChipOnInit()) {
                if (tenant === 'vcs' || !isUserProAdvantage || allowedProAdLocales.includes(CALocale)) {
                  initNR();
                  scriptLoad();
                }
              }
            } else {
              console.info('Chat is disabled outside business hours');
              handleOfflineChat();
            }
          });
        } else {
          console.warn('CA Script already initialized');
        }
      }
    }, 2000);
  } catch (err) {
    const error = err as Error;
    logNR('CA_ERROR', { errorMessage: error.message, errorStack: error.stack });
  }
};

export { initChatAnywhere };
