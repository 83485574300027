/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import { getContext, pageNames, pageUrlMapping } from '../pageMapping';
import { WindowType } from '../../../types/windowType';
import { getTrans } from './translations';
import { logNR } from '../../../utils/newRelic';
import { isMobileOrTablet } from '../../../utils/mobile-utils';
import { isHelpPanelAnywherePage, enhancedPreChatPages } from './helper';
import { getCAConfig } from '../../../utils/ca-env';

let foundValue = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let properties: any;

declare const window: WindowType;

/** If locale is not en-us remove the button */
// Commenting this as now chat chip will be visible in all locales
// const hideChatChipforOtherLocales = (locale: string) => {
//   const customButton = document.getElementById('caHelpButton');
//   if (locale?.toLowerCase() !== 'en-us') {
//     (customButton as HTMLButtonElement).style.display = 'none';
//   }
// };
// eslint-disable-next-line camelcase
function matchInArray_builder(expr, isValueFound) {
  if (!isValueFound) {
    const fullExpr = new RegExp(
      expr
        .map((x) => x.source) // Just if you need to provide RegExp instances instead of strings or ...
        // .map(x=>x.substring(1, x.length -2)  // ...if you need to provide strings enclosed by "/" like in original question.
        .join('|'),
    );

    return (str) => str.match(fullExpr);
  }
  return undefined;
}

// Getting Properties from page name
function getPageCategoryFromURLMapping(pageURL: string, pageCategory: string) {
  if (pageURL)
    if (pageURL.includes(getTrans('promotionalProduct')) || pageURL.includes(getTrans('clothingBags'))) {
      pageCategory = 'promotionalproducts';
    } else if (pageURL.includes(getTrans('business-cards-connect'))) {
      pageCategory = 'vistaConnect';
    } else if (pageURL.includes(getTrans('helpCenter'))) {
      pageCategory = 'helpCenter';
    } else if (pageURL.includes(getTrans('photoGifts'))) {
      pageCategory = 'photogifts';
    } else if (pageURL.includes(getTrans('hub'))) {
      pageCategory = 'content hub';
    } else if (pageURL.includes(getTrans('marketingMaterials'))) {
      pageCategory = 'marketingmaterials';
    } else if (pageURL.includes(getTrans('graphicDesign'))) {
      pageCategory = 'graphicDesign';
    } else if (pageURL.includes(getTrans('packaging'))) {
      pageCategory = 'packaging';
    } else if (pageURL.includes(getTrans('digitalMarketing'))) {
      pageCategory = 'digitalmarketing';
    } else if (pageURL.includes(getTrans('signsPosters'))) {
      pageCategory = 'signs';
    } else if (pageURL.includes(getTrans('businessCards'))) {
      pageCategory = 'businesscards';
    } else if (pageURL.includes(getTrans('studio'))) {
      pageCategory = 'studio';
    } else if (pageURL.includes(getTrans('qr'))) {
      pageCategory = 'qrcodegenerator';
    } else if (pageURL.includes(getTrans('gallery'))) {
      pageCategory = 'gallery';
    } else if (pageURL.includes(getTrans('signage'))) {
      pageCategory = 'signage';
    } else if (pageURL.includes(getTrans('msp'))) {
      pageCategory = 'msp';
    } else if (
      pageURL.includes(getTrans('mailing-service-upload')) ||
      pageURL.includes(getTrans('mailing-service-review')) ||
      pageURL.includes(getTrans('mailing-service-landing'))
    ) {
      pageCategory = 'msp';
    } else if (pageURL.includes(getTrans('search'))) {
      pageCategory = 'search';
    } else if (pageCategory == null) {
      pageCategory = 'Others';
    }
  return pageCategory;
}

// Getting properties from page name
function getPageCategoryFromPageName(pageName: string, foundValue: boolean, pageCategory: string) {
  if (
    pageName &&
    (pageName.includes('category page') ||
      pageName.includes('discovery page') ||
      pageName.includes('landing') ||
      (pageName.includes('gallery') &&
        pageName !== 'gallery:design services' &&
        pageName !== 'studio:main page:design services') ||
      pageName.includes('product page') ||
      pageName.includes('content hub') ||
      (pageName.includes('design services') &&
        pageName !== 'gallery:design services' &&
        pageName !== 'studio:main page:design services'))
  ) {
    const nameMapping = [
      /newarrivals/,
      // /qr/,
      // /digitalmarketing/,
      /labels/,
      /stickers/,
      /posters/,
      /banners/,
      /businesscards/,
      /logos:design/,
      /page:design/,
      /packaging/,
      // /photogifts/,
      /samplekit/,
      /christmascards/,
      /businessholiday/,
      /invitations/,
      // /stationery/,
      // /marketingmaterials/,
      /clothingandbags/,
      /consumerholidayhub/,
      /design services:dashboard/,
      /thankyoucards/,
      /page:en-us:design/,
      /design services: hub page/,
    ];

    const matchInArray = matchInArray_builder(nameMapping, foundValue);
    if (matchInArray && matchInArray(pageName)) {
      pageCategory = matchInArray(pageName);
      foundValue = true;
    }
    if (pageCategory == null) {
      pageCategory = 'Others';
    }
  } else if (
    (pageName?.includes('calendars:configure - option') || pageName === 'photobooks:configure - option') &&
    pageName !== 'postercalendars:configure - option'
  ) {
    pageCategory = 'apdc';
  } else if (pageName?.includes(':configure - option')) {
    pageCategory = 'pdc';
  } else if (pageName?.includes(':configure - recommendation')) {
    pageCategory = 'upSellCrossSell';
  } else if (pageName?.includes('gallery:design services') || pageName?.includes('main page:design services')) {
    pageCategory = 'galleryDesignServices';
  } else if (pageName?.includes(':configure - quantity') || pageName?.includes(':studio:review')) {
    pageCategory = 'ppag';
  }

  pageNames?.forEach((name) => {
    // eslint-disable-next-line no-prototype-builtins
    if (name.hasOwnProperty(pageName)) {
      pageCategory = name[pageName];
    }
  });
  return pageCategory;
}

// Set the final context and properties from value
function setSfPropertiesAndAdditionalProperties(
  pageCategory: string,
  pageSection: string,
  pageStage: string,
  languageLocale: string,
  pagePath: string,
  pageName: string,
) {
  const properties = pageUrlMapping()[pageCategory];
  const locale = getCAConfig().locale.toUpperCase();
  const tenant = getCAConfig().tenant;
  const finalContext = {};
  const context = getContext();

  if (tenant === 'vcs') {
    context['issueCategory'] = {
      label: 'Issue Category',
      transcriptFields: ['Issue_Category__c'],
      displayToAgent: true,
      // value: 'Internal',
    };
  }

  const isEnhancedPreChatPage = enhancedPreChatPages();
  Object.keys(context).forEach((property) => {
    finalContext[property] = context[property];
    if (tenant === 'vcs' && property == 'category') {
      finalContext[property] = {
        label: 'Categories',
        transcriptFields: ['Categories__c'],
        displayToAgent: true,
      };
    }

    if (
      property === 'priority' &&
      (pageCategory === 'cart' || pageCategory === 'checkoutShipping' || pageCategory === 'checkoutPayment')
    ) {
      finalContext[property].value = properties.sfProperties[property];
    }
    if (!context[property].value) {
      const pageNameProperty = properties.sfProperties.pageName;
      if (property === 'caseOrigin') {
        if (tenant === 'vcs') {
          finalContext[property].value = 'ChatAnywhereChat';
        } else if (isMobileOrTablet()) {
          if (isHelpPanelAnywherePage()) {
            finalContext[property].value = `VistaPrint-ChatAnywhere-Mobile-${locale}-${pageNameProperty}-HelpPanel`;
          } else {
            finalContext[property].value = `VistaPrint-ChatAnywhere-Mobile-${locale}-${pageNameProperty}`;
          }
        } else if (isHelpPanelAnywherePage()) {
          finalContext[property].value = `VistaPrint-ChatAnywhere-${locale}-${pageNameProperty}-HelpPanel`;
        } else {
          finalContext[property].value = `VistaPrint-ChatAnywhere-${locale}-${pageNameProperty}`;
        }
      } else if (property === 'subject') {
        if (!isEnhancedPreChatPage) {
          finalContext[property].value = `Chat - ${pageNameProperty}`;
        }
        if (tenant === 'vcs') {
          finalContext[property].value = `VistaNext Chat - ${pageNameProperty}`;
        }
      } else if (property === 'description') {
        if (!isEnhancedPreChatPage) {
          finalContext[property].value = `${pageNameProperty} case`;
        }
      } else if (property === 'pageStage') {
        finalContext[property].value = pageStage;
      } else if (property === 'pageSection') {
        finalContext[property].value = pageSection;
      } else if (property === 'pagePath') {
        finalContext[property].value = pagePath;
      } else if (property === 'pageName') {
        finalContext[property].value = pageName;
      } else if (isEnhancedPreChatPage) {
        if (!(property === 'category' || property === 'subCategory')) {
          finalContext[property].value = properties.sfProperties[property];
        }
      } else if (property == 'chatLocale' && tenant === 'vcs') {
        finalContext[property].value = languageLocale;
      } else {
        finalContext[property].value = properties.sfProperties[property];
      }
    }
  });
  const { additionalProperties } = properties;
  console.info('Context Values', { finalContext });
  return { finalContext, additionalProperties, languageLocale };
}

/**
 * Get properties on the basis of page load event
 * either page name or url mapping
 * @param pageLoadEvent
 * @param foundValue
 * @returns
 */
const getPageBasedProperties = async (pageLoadEvent, foundValue: boolean) => {
  const pageName = pageLoadEvent?.pageName;
  let pageCategory;
  const pageSection = pageLoadEvent?.pageSection;
  const languageLocale = `${pageLoadEvent?.language}-${pageLoadEvent?.locale}`;
  const pageStage = pageLoadEvent?.pageStage;
  const pagePath = pageLoadEvent?.path;
  const pageURL = window.location.href;

  // console.log('Page Attributes', pageName, pageSection, pageStage, pageLanguage, pageURL, pagePath);

  /** locale check to hide chat chip for other locales */
  // hideChatChipforOtherLocales(languageLocale);

  // Stationary has conflicting page so we will add it before otherwise page url mappings are added at end
  if (pageURL.includes(getTrans('stationery'))) {
    pageCategory = 'stationery';
  }

  // Finding page category from page name and url
  // Conditions will be fulfiled by any one of these functions
  pageCategory = getPageCategoryFromPageName(pageName?.toLowerCase(), foundValue, pageCategory);
  pageCategory = getPageCategoryFromURLMapping(pageURL, pageCategory);

  return setSfPropertiesAndAdditionalProperties(
    pageCategory,
    pageSection,
    pageStage,
    languageLocale,
    pagePath,
    pageName,
  );
};

const getMetaContentByName = (name: string): string | null => {
  const metaTag = document.querySelector<HTMLMetaElement>(`meta[name="${name}"]`);
  return metaTag?.content || null;
};

function findPageLoadEvent(dataLayer) {
  const pageName = getMetaContentByName('pageName');
  const pageStage = getMetaContentByName('pageStage');
  const pageSection = getMetaContentByName('pageSection');
  if (pageName && pageSection && pageStage) {
    console.info('Using meta tags');
    return {
      pageName,
      pageStage,
      pageSection,
      path: window.location.pathname,
      language: window.CALocale?.split('-')?.[0] || '',
      locale: window.CALocale?.split('-')?.[1] || '',
    };
  }
  const pageLoadEvent = dataLayer?.find(
    (val) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      val?.event === 'Loaded a Page' || (val?.event === 'Studio Errors' && window.location.href.includes('/studio')),
  );
  return pageLoadEvent;
}
// eslint-disable-next-line consistent-return
const getProperties = async () => {
  try {
    const caChatConfig = getCAConfig();
    foundValue = false;
    if (window && typeof window !== 'undefined') {
      // If page section = Discovery, Gallery, Product Page, Category Page we will do regex mapping
      // If regex matches, then we pick that key and map it
      // If not found, send others in all cases
      // If not the page section match, we consider it like home page, and directly map the values

      /**  For Local testing, where dataLayer is not available */
      if (!window.dataLayer || !Array.isArray(window.dataLayer)) {
        window.dataLayer = [];
      }
      if (window.location.pathname.includes('/studio')) {
        window.dataLayer.push({
          languageLocale: 'en-US',
          language: 'en',
          locale: 'US',
          event: 'Loaded a Page',
          pageSection: 'Studio',
          pageStage: 'Studio',
          pageName: 'Studio',
          path: '/studio',
        });
      } else if (window.location.href.includes('localhost')) {
        window.dataLayer.push({
          languageLocale: 'en-US',
          language: 'en',
          locale: 'US',
          event: 'Loaded a Page',
          pageSection: 'My Account',
          pageStage: 'My Account',
          pageName: 'My Account:My Projects',
          path: '/',
        });
      }
      // Find Page Load Event from datalayer
      let pageLoadEvent = findPageLoadEvent(window.dataLayer);
      if (pageLoadEvent) {
        return new Promise((resolve) => {
          resolve(getPageBasedProperties(pageLoadEvent, foundValue));
        });
      }
      let interval;
      // Set interval to keep checking after 0.5 sec for page load event
      return new Promise((resolve) => {
        let counter = 0;
        interval = setInterval(() => {
          counter += 1;
          pageLoadEvent = findPageLoadEvent(window.dataLayer);
          if (pageLoadEvent) {
            clearInterval(interval);
            resolve(getPageBasedProperties(pageLoadEvent, foundValue));
          } else if (counter >= 10) {
            // If counter > 10, that means it is above 5 seconds, then we take the others case.
            clearInterval(interval);
            if (!window.locale) {
              window.locale = caChatConfig.locale;
            }
            const windowlocalesplit = window?.locale?.split('-');
            let pageLoadEvent = findPageLoadEvent(window?.dataLayer);
            if (!pageLoadEvent) {
              pageLoadEvent = {
                event: 'Loaded a Page',
                language: windowlocalesplit?.[0],
                languageLocale: window?.locale,
                locale: windowlocalesplit?.[1],
                pageName: 'Others',
                pageSection: 'Others',
                pageStage: 'Others',
                url: window.location.href,
              };
              if (!Array.isArray(window.dataLayer)) {
                window.dataLayer = [];
              }
              window.dataLayer.push(pageLoadEvent);
            }
            // console.log('IN LAST PAGE LOAD RESOLVE');
            const result = getPageBasedProperties(pageLoadEvent, foundValue);
            resolve(result);
          }
        }, 500);
      });
    }
  } catch (err) {
    const error = err as Error;
    logNR('CA_PAGE_PROPERTIES_ERROR', { errorMessage: error.message, errorStack: error.stack });
  }
};

const ContextBuilder: any = async () => {
  if (!properties) properties = await getProperties();
  return properties;
};

export default ContextBuilder;
