import { isMobileOrTablet } from '../utils/mobile-utils';
//code to make chat chip Draggable
var pos1 = 0,
  pos2 = 0,
  pos3 = 0,
  pos4 = 0;
var previousElementPositionTop;
var previousElementPositionRight;
var defaultElementPositionTop;
var defaultElementPositionLeft;
var isDragged = false;
const classArray = [
  'conflicting',
  'conflictingXS',
  'conflictingLogoMaker',
  'conflictingApdc',
  'conflictingPpag',
  'chatAnywhereConflictingUploadPrint',
  'conflictingPackagingStudio',
  'conflictingQuickUpload',
  'conflictingBrowseDesignBtnPage',
  'conflictingCalendarBtnPage',
  'conflictingStudioBtnPage',
  'conflictingPromotionalProducts',
];

function dragElement(element) {
  //For Mobile
  if (isMobileOrTablet()) {
    window.addEventListener(
      'orientationchange',
      function () {
        let ctaBtn = document.getElementsByClassName('caHelpButtonEnabled')?.[0];
        element.style.left = defaultElementPositionLeft;
        element.style.top = defaultElementPositionTop;
      },
      false,
    );

    element.addEventListener('touchmove', function (e) {
      e = e || window.event;
      e.preventDefault();
      // e.stopPropagation();
      // grab the location of touch
      var touchLocation = e.targetTouches[0];

      if (!isDragged) {
        defaultElementPositionLeft = element.style.left;
        defaultElementPositionTop = element.style.top;
      }
      // assign box new coordinates based on the touch.
      element.style.left = touchLocation.clientX + 'px';
      element.style.top = touchLocation.clientY + 'px';
      previousElementPositionTop = element.style.top;
      previousElementPositionRight = element.style.right;
      isDragged = true;
    });
  } else {
    // for Desktop
    document.body.addEventListener('mouseleave', closeDragElement);
    if (element === document.getElementsByClassName('cMinimizedChatCA')?.[0]) {
      element.style.top = previousElementPositionTop;
      element.style.right = previousElementPositionRight;
    }
    function dragMouseDown(mouseEvent) {
      mouseEvent.preventDefault();
      // get the mouse cursor position at startup:
      window.embedded_svc.isCACTAClicked = true;
      pos3 = mouseEvent.pageX;
      pos4 = mouseEvent.pageY;

      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = chatChipDrag;
    }
    element.onmousedown = dragMouseDown;
    embedded_svc.addEventHandler('afterMaximize', function () {
      element.style.top = previousElementPositionTop;
      element.style.right = previousElementPositionRight;
    });
    function chatChipDrag(mouseEvent) {
      mouseEvent.preventDefault();

      window.embedded_svc.isDragStarted = true;
      window.embedded_svc.isCACTAClicked = false;
      if (document.getElementById('isDragStarted')) {
        document.getElementById('isDragStarted').value = 'true';
      }

      // calculate the new cursor position:
      pos1 = pos3 - mouseEvent.pageX;
      pos2 = pos4 - mouseEvent.pageY;
      pos3 = mouseEvent.pageX;
      pos4 = mouseEvent.pageY;
      // set the element's new position:
      if (element.offsetTop >= 0 && element.offsetTop <= window.innerHeight - element.offsetHeight) {
        previousElementPositionTop = element.style.top = element.offsetTop - pos2 + 'px';
      } else if (element.offsetTop > window.innerHeight - element.offsetHeight) {
        previousElementPositionTop = element.style.top = window.innerHeight - element.offsetHeight + 'px';
      } else {
        previousElementPositionTop = element.style.top = 0;
      }
      let offsetright = document.body.clientWidth - element.offsetLeft - element.offsetWidth;
      if (offsetright >= 0 && offsetright <= window.innerWidth - element.offsetWidth) {
        previousElementPositionRight = element.style.right = offsetright + pos1 + 'px';
      } else if (offsetright > window.innerWidth - element.offsetWidth) {
        previousElementPositionRight = element.style.right = window.innerWidth - element.offsetWidth + 'px';
      } else {
        previousElementPositionRight = element.style.right = 0;
      }
      for (let className of classArray) {
        if (element.classList.contains(className)) {
          element.classList.remove(className);
          break;
        }
      }
    }
  }
}
function closeDragElement() {
  // stop moving when mouse button is released:
  document.onmouseup = null;
  document.onmousemove = null;
}

export { dragElement };
