import { IConfigData } from '../../../types/app-interfaces';

export const devsConfig: IConfigData = {
  tenant: 'vistaprint',
  env: 'sandbox',
  locale: 'en-us',
  checkBusinessHour: false,
  baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-yzvdd4.salesforceliveagent.com/content',
  eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I07000000Cbg4EAC_185dd6e1140',
  deploymentId: '57207000000Gp73',
  baseLiveAgentURL: 'https://d.la2s-core1.sfdc-yzvdd4.salesforceliveagent.com/chat',
  isOfflineSupportEnabled: false,
  baseCoreURL: 'https://vpcare--cadevs.sandbox.my.salesforce.com',
  communityEndpointURL: 'https://vpcare--cadevs.sandbox.my.salesforce-sites.com/public',
  orgID: '00D9X00000BCgTN',
  eswConfigDevName: 'ChatAnywhere_Embedded_Chat',
  defaultButtonId: '57307000000L1Go',
  ppagButtonId: '57307000000L1Go',
  checkoutButtonId: '57307000000L1Go',
  digitalButtonId: '57307000000L1Go',
  designButtonId: '57307000000L1Go',
  mspButtonId: '57307000000L1Go',
  loadLightningScript: true,
  enableDraggable: true,
  addDefaultPageEvent: true,
  gaTrackingEnabled: true,
  segmentTrackingEnabled: false,
  ctaPreLoadEnabled: true,
  enableForMobile: true,
  timeToShowAutomatedInviteInSeconds: 10,
  timeForChatDuplicationCookieExpiry: 10,
};
