import { getCAConfig } from './ca-env';

const languageMap = {
  'en-ie': 'en_US',
  'en-gb': 'en_US',
  'en-au': 'en_US',
  'en-nz': 'en_US',
  'en-sg': 'en_US',
  'de-at': 'de',
  'de-de': 'de',
  'de-ch': 'de',
  'pt-pt': 'pt_BR',
  'fi-fi': 'fi',
  'sv-se': 'sv',
  'nb-no': 'no',
  'da-dk': 'da',
  'nl-nl': 'nl_NL',
  'nl-be': 'nl_NL',
  'fr-fr': 'fr',
  'fr-ch': 'fr',
  'fr-be': 'fr',
  'it-it': 'it',
  'it-ch': 'it',
  'en-us': 'en_US',
  'en-ca': 'en_US',
  'en-in': 'en_US',
  'fr-ca': 'fr',
  'es-es': 'es',
  'es-us': 'es_MX',
};

const languageMapping = (): string => {
  const { locale } = getCAConfig();
  return languageMap[locale];
};

export default languageMapping;
