import { chatChipVisblePages } from '../CACTA/JSComponents/pageMapping';
import { getTrans } from '../CACTA/JSComponents/JS/translations';
import { logNR } from '../utils/newRelic';
import { getCAConfig } from '../utils/ca-env';
import { API_ENDPOINTS } from '../config/api-config';

const sendRequestToChannelProvider = async (tenant: string, locale: string, feature: string) => {
  const { env } = getCAConfig();
  // eslint-disable-next-line max-len
  const BASE_URL = env === 'production' ? API_ENDPOINTS.GCP_PRODUCTION : API_ENDPOINTS.GCP_STAGING;
  const RequestURL = `${BASE_URL}/v3/channels/chat?tenant=${tenant}&locale=${locale}&feature=${feature}`;
  const prodAPIKey = process.env.REACT_APP_CA_GCP_PROD_API_KEY;
  const stagingAPIKey = process.env.REACT_APP_CA_GCP_DEV_API_KEY;
  const API_KEY = env === 'production' ? prodAPIKey : stagingAPIKey;
  let response;
  try {
    response = await fetch(RequestURL, {
      method: 'GET',
      headers: {
        'x-api-key': API_KEY || '',
      },
    });
    if (response.status === 404) {
      throw new Error('Unable to find an existing entry');
    }
    const headers = Object.fromEntries(response.headers);
    const xGCPRequestId = headers['x-gcp-request-id'];
    const responseStatus = response.status;
    const requestURL = response.url;
    const dataFromChannelProvider = await response.json();
    dataFromChannelProvider.gcpRequestId = xGCPRequestId;
    dataFromChannelProvider.responseStatus = responseStatus;
    dataFromChannelProvider.requestURL = requestURL;
    dataFromChannelProvider.apiSuccess = true;
    console.info({ dataFromChannelProvider });
    return dataFromChannelProvider;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    logNR('CA_CHANNEL_PROVIDER_ENTRY_NOT_FOUND', { errorMessage: error.message, errorStack: error.stack });
    return { status: { isChatEnabled: false }, apiSuccess: false };
  }
};

const getFeatureFromPage = (currentPage: string) => {
  let feature: string;
  switch (currentPage) {
    case 'checkout':
    case 'cart':
      feature = 'CheckOutPageEmbedded';
      break;
    case 'template-design':
      feature = 'Studio';
      break;
    default:
      feature = 'ContactUsPage';
  }
  return feature;
};

const isChatEnabled = async () => {
  // let isWithinBusinessHours = false;
  const pageURL = window.location.href;
  const currentPage = chatChipVisblePages.filter((ele) => pageURL.includes(getTrans(ele)))?.[0];
  const TENANT = 'vistaprint';
  let FEATURE = getFeatureFromPage(currentPage);
  const { locale, tenant } = getCAConfig();

  if (tenant === 'vcs') {
    FEATURE = 'ProAdvantageChat';
  }
  const LOCALE = locale;

  const channelProvderData = await sendRequestToChannelProvider(TENANT, LOCALE, FEATURE);
  // isWithinBusinessHours = channelProvderData.status.isChatEnabled;
  return channelProvderData;
};
export { isChatEnabled, sendRequestToChannelProvider };
