import { getCAConfig } from '../../../utils/ca-env';
import { API_ENDPOINTS } from '../../../config/api-config';

let translationCache = {};

const fetchTranslations = async (locale: string) => {
  try {
    const response = await fetch(API_ENDPOINTS.TRANSLATIONS(locale));
    if (!response.ok) {
      throw new Error('Failed to fetch translations');
    }
    const translations = await response.json();
    translationCache = translations; // Cache the full translations object
  } catch (error) {
    console.error(error);
    translationCache = {}; // Fallback to an empty object
  }
};

const initTranslations = async () => {
  if (Object.keys(translationCache).length === 0) {
    const { locale } = getCAConfig();
    await fetchTranslations(locale);
  }
};

const getTrans = (key) => {
  // const locale = getCAConfig().locale;

  // Ensure cache exists
  if (!translationCache || Object.keys(translationCache).length === 0) {
    return key; // Fallback to key if translations are not available
  }

  const transWord = translationCache[key];
  return transWord || key; // Return the translation or the key if not found
};

export { getTrans, initTranslations };
