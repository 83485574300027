import { IConfigData, ILocaleConfigType } from '../../../types/app-interfaces';

export const vcsSandboxConfig: ILocaleConfigType = {
  'en-us': {
    eswConfigDevName: 'ChatAnywhere_VistaNext_EN_US_Embedded_Chat',
    defaultButtonId: '573Ou0000003bhN',
    ppagButtonId: '',
    checkoutButtonId: '',
    digitalButtonId: '',
    designButtonId: '',
    mspButtonId: '',
    checkBusinessHour: false,
  },
};

export const vcsCommonConfig = {
  baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-yfeipo.salesforceliveagent.com/content',
  eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IOu0000004huPMAQ_19006785236',
  deploymentId: '572DE000000L2Ya',
  baseLiveAgentURL: 'https://d.la2s-core1.sfdc-yfeipo.salesforceliveagent.com/chat',
  isOfflineSupportEnabled: false,
  baseCoreURL: 'https://vistaprint--pcopy.sandbox.my.salesforce.com',
  communityEndpointURL: 'https://vistaprint--pcopy.sandbox.my.salesforce-sites.com/public',
  orgID: '00DDE0000043kkj',
  loadLightningScript: true,
  enableDraggable: false,
  addDefaultPageEvent: true,
  gaTrackingEnabled: true,
  segmentTrackingEnabled: false,
  ctaPreLoadEnabled: true,
  enableForMobile: true,
  timeToShowAutomatedInviteInSeconds: 5,
  timeForChatDuplicationCookieExpiry: 10,
};

export const getVistaNextSandboxConfig = (locale: string): IConfigData => {
  const localeConfig = vcsSandboxConfig[locale];
  const isStudio = window.location.href.includes('/studio');
  if (isStudio) {
    localeConfig.defaultButtonId = '573Ou0000006WRp';
    localeConfig.eswConfigDevName = 'EmbeddedServiceLiveAgent_Parent04IOu0000006brJMAQ_191b32576f0';
  }
  return {
    env: 'sandbox',
    tenant: 'vcs',
    locale,
    ...localeConfig,
    ...vcsCommonConfig,
  };
};
