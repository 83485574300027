export type Locale =
  | 'en-ie'
  | 'en-gb'
  | 'en-au'
  | 'en-nz'
  | 'en-sg'
  | 'de-at'
  | 'de-de'
  | 'de-ch'
  | 'pt-pt'
  | 'fi-fi'
  | 'sv-se'
  | 'nb-no'
  | 'da-dk'
  | 'nl-nl'
  | 'nl-be'
  | 'fr-fr'
  | 'fr-ch'
  | 'fr-be'
  | 'it-it'
  | 'it-ch'
  | 'en-us'
  | 'en-ca'
  | 'en-in'
  | 'fr-ca'
  | 'es-es'
  | 'es-us';
export const localesArray: Locale[] = [
  'en-ie',
  'en-gb',
  'en-au',
  'en-nz',
  'en-sg',
  'de-at',
  'de-de',
  'de-ch',
  'pt-pt',
  'fi-fi',
  'sv-se',
  'nb-no',
  'da-dk',
  'nl-nl',
  'nl-be',
  'fr-fr',
  'fr-ch',
  'fr-be',
  'it-it',
  'it-ch',
  'en-us',
  'en-ca',
  'en-in',
  'fr-ca',
  'es-es',
  'es-us',
];
export const localeURLMap: { pattern: RegExp; value: Locale }[] = [
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint\.co\.uk\/.*)$/,
    value: 'en-gb',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.ie\/.*)$/,
    value: 'en-ie',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint\.com\/(?!es(?:\/|$))(?!.*es-us).*$).*/,
    value: 'en-us',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.com.au\/.*)$/,
    value: 'en-au',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.co.nz\/.*)$/,
    value: 'en-nz',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.sg\/.*)$/,
    value: 'en-sg',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.fr\/.*)$/,
    value: 'fr-fr',
  },
  {
    pattern:
      /^(http|https):\/\/((www\.)?vistaprint\.(ch)\/fr\/.*)|(http|https):\/\/((www\.)?vistaprint\.(ch)\/.*fr-ch.*)$/,
    value: 'fr-ch',
  },
  {
    pattern:
      /^(http|https):\/\/((www\.)?vistaprint\.(be)\/.*fr-be.*)|(http|https):\/\/((www\.)?vistaprint\.(be)\/fr\/.*)$/,
    value: 'fr-be',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint\.ca\/(?!fr(?:\/|$))(?!.*fr-ca).*$).*/,
    value: 'en-ca',
  },
  {
    pattern:
      /^(http|https):\/\/((www\.)?vistaprint\.(ca)\/fr\/.*)|(http|https):\/\/((www\.)?vistaprint\.(ca)\/.*fr-ca.*)$/,
    value: 'fr-ca',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.de\/.*)$/,
    value: 'de-de',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.at\/.*)$/,
    value: 'de-at',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint\.ch\/(?!(fr|it)(?:\/|$))(?!.*((fr-ch)|(it-ch))).*$).*/,
    value: 'de-ch',
  },
  {
    pattern:
      /^(http|https):\/\/((www\.)?vistaprint\.(ch)\/it\/.*)|(http|https):\/\/((www\.)?vistaprint\.(ch)\/.*it-ch.*)$/,
    value: 'it-ch',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.it\/.*)$/,
    value: 'it-it',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.dk\/.*)$/,
    value: 'da-dk',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.no\/.*)$/,
    value: 'nb-no',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.se\/.*)$/,
    value: 'sv-se',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.fi\/.*)$/,
    value: 'fi-fi',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.es\/.*)$/,
    value: 'es-es',
  },
  {
    pattern:
      /^(http|https):\/\/((www\.)?vistaprint\.(com)\/es\/.*)|(http|https):\/\/((.*\.)?vistaprint\.(com)\/.*es-us.*)$/,
    value: 'es-us',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint\.be\/(?!fr(?:\/|$))(?!.*fr-be).*$).*/,
    value: 'nl-be',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.nl\/.*)$/,
    value: 'nl-nl',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.pt\/.*)$/,
    value: 'pt-pt',
  },
  {
    pattern: /^(http|https):\/\/((www\.)?vistaprint.in\/.*)$/,
    value: 'en-in',
  },
];

export interface ILocaleConfigType {
  [x: string]: {
    eswConfigDevName: string;
    defaultButtonId: string;
    ppagButtonId: string;
    checkoutButtonId: string;
    digitalButtonId: string;
    designButtonId: string;
    mspButtonId: string;
    checkBusinessHour: boolean;
  };
}
export interface IConfigData {
  env: 'production' | 'sandbox';
  tenant: string;
  locale: string;
  checkBusinessHour: boolean;
  baseLiveAgentContentURL: string;
  eswLiveAgentDevName: string;
  deploymentId: string;
  baseLiveAgentURL: string;
  isOfflineSupportEnabled: boolean;
  baseCoreURL: string;
  communityEndpointURL: string;
  orgID: string;
  eswConfigDevName: string;
  defaultButtonId: string;
  ppagButtonId: string;
  checkoutButtonId: string;
  digitalButtonId: string;
  designButtonId: string;
  mspButtonId: string;
  loadLightningScript: boolean;
  enableDraggable: boolean;
  addDefaultPageEvent: boolean;
  gaTrackingEnabled: boolean;
  segmentTrackingEnabled: boolean;
  ctaPreLoadEnabled: boolean;
  enableForMobile: boolean;
  timeToShowAutomatedInviteInSeconds: number;
  timeForChatDuplicationCookieExpiry: number;
}
