import ContextBuilder from '../JS/Context';
import '../CSS/defaultPage.scss';
import { chatChipVisblePages, chatChipNonVisblePages } from '../pageMapping';
// eslint-disable-next-line import/extensions
import { getCAConfig } from '../../../utils/ca-env';
import { getTrans } from '../JS/translations';

//let positionClass = 'hideCTAbutton';

const CABUTTON = () => ({
  Default: `
  <button 
    type="button" 
    class="swan-button swan-button-skin-primary swan-button-with-icon-left swan-compact-mode caHelpButtonEnabled hideCTAbutton"
    id="caHelpButton"
  >
    <img
      src="https://swan.prod.merch.vpsvc.com/v3/icons/live_chat.0c91b22f94451864870cbcfb132e04d6.svg"
      alt="image"
      class="swan-icon swan-icon-mask swan-icon-size-standard swan-icon-skin-standard swan-icon-type-image caHelpIconBg"
      id="caButtonIcon"
    />
    <div id="caButtonText" >
    ${getTrans('ca-message')}
    </div>
  </button>`,
});

export const showChatChipOnInit = () => {
  const pageURL = window.location.href;

  const { tenant, locale } = getCAConfig();
  if (tenant === 'vcs') {
    return isVistaNextEnabledPage();
  }
  // eslint-disable-next-line no-unneeded-ternary
  if (locale === 'en-gb' || locale === 'fr-fr') {
    return chatChipVisblePages.find((ele) => pageURL.includes(getTrans(ele))) ? true : false;
  }
  return chatChipNonVisblePages.find((ele) => pageURL.includes(getTrans(ele))) ? false : true;
};

export const isVistaNextEnabledPage = () => {
  const pageURL = window.location.href;
  const VistaNextPages = ['ourproshop.com', 'proshopnext.com'];
  return VistaNextPages.find((ele) => pageURL.includes(ele)) ? true : false;
};

const getButtonHTML = () => {
  // const pageUrl = window.location.href;

  const buttonHtml = CABUTTON().Default;
  // Commenting below line to keep CA hidden until CP API call
  // if (showChatChipOnInit()) {
  //   //** If chatchip shown, remove the hideCTAButton Class */

  //   //buttonHtml = CABUTTON.Default.replace('hideCTAbutton', 'staticClass');

  //   /** Button will be in disabled state initally */
  //   let positionToInsert = buttonHtml.indexOf('>');
  //   buttonHtml = buttonHtml.slice(0, positionToInsert) + ' disabled=true ' + buttonHtml.slice(positionToInsert);
  // }
  // TODO: Temporary commenting since page is shown only on selective pages
  // if (isMobile) {
  //   for (const [key, value] of Object.entries(conflictingMobilePageMapping)) {
  //     let translatedKey = getTrans(key, 'en');
  //     if (pageUrl.includes(translatedKey)) {
  //       buttonHtml = CABUTTON.Default.replace('staticClass', value);
  //       break;
  //     }
  //   }
  // }
  //  for (let i in conflictingPageMapping) {
  //   let translatedKey = getTrans(conflictingPageMapping[i], 'en');
  //   if (pageUrl.includes(translatedKey)) {
  //     buttonHtml = CABUTTON.Default.replace('staticClass', 'hideCTAbutton');
  //     break;
  //   }
  // }
  return { buttonHtml };
};

const getCTAForPageUrl = async () => {
  // Create context based on the page URL
  const ctaConfig = await ContextBuilder();
  console.info({ ctaConfig });
  // eslint-disable-next-line dot-notation
  const sfProperties = ctaConfig['finalContext'];
  return { sfProperties };
};
export { getCTAForPageUrl, getButtonHTML };
