import { getCAConfig } from './ca-env';

export const getButtonName = (buttonId?: string) => {
  let buttonName = 'DEFAULT_BUTTON';
  Object.entries(getCAConfig()).forEach(([key, value]) => {
    if (value === buttonId) {
      const btnName = `${key.substring(0, key.indexOf('Button')).toUpperCase()}_BUTTON`;
      buttonName = btnName;
    }
  });
  console.info(buttonName);
  return buttonName;
};
